import config from './../../config';
import axios from 'axios';
import {
  retrieveHelper,
  pc,
  registerEvent,
  ec,
  timingCategory,
} from '../utils';

let apiKey = config.API_KEY;

// main instance
export const instance = axios.create({
  baseURL: `${config.URL}`,
});

instance.interceptors.request.use(function (config) {
  const visitorId = retrieveHelper(pc.visitorId);
  const employeeId = retrieveHelper(pc.employeeId);

  config.headers.common['sessionId'] = `${visitorId},${employeeId}`;
  config.headers.common['x-api-key'] = apiKey;
  config.metadata = { startTime: performance.now() };
  return config;
});

// Add a response interceptor
instance.interceptors.response.use(
  response => {
    response.config.metadata.endTime = performance.now();
    response.duration =
      response.config.metadata.endTime - response.config.metadata.startTime;
    let url = response.config.url;
    if (url.includes('profile')) {
      url = url.split('profile/')[0] + 'profile/';
    }
    if (url.includes('coordinates')) {
      url = url.split('coordinates')[0];
    }
    registerEvent(
      ec.Timing,
      timingCategory(url) + '_' + url,
      JSON.stringify({
        Time: response.duration,
        UserId: retrieveHelper(pc.employeeId),
      })
    );
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    const employeeId = retrieveHelper(pc.employeeId);

    registerEvent(
      ec.Error,
      JSON.stringify(originalRequest.url),
      `'OrignialRequest: ' ${JSON.stringify(
        originalRequest
      )} || 'Error: ' ${JSON.stringify(
        error
      )} || 'UserID: ' ${employeeId} || 'Timestamp: ' ${new Date().toLocaleString()}`
    );

    return Promise.reject(error);
  }
);

// login instance
export const loginInstance = axios.create({
  baseURL: `${config.URL}`,
});

loginInstance.interceptors.request.use(function (config) {
  config.headers.common['x-api-key'] = apiKey;
  return config;
});

// onboard instance
export const onboardInstance = axios.create({
  baseURL: `${config.URL}`,
});

onboardInstance.interceptors.request.use(function (config) {
  config.headers.common['x-api-key'] = apiKey;
  return config;
});

// otc onboard instance
export const OTCOnboardInstance = axios.create({
  baseURL: `${config.URL}`,
});

OTCOnboardInstance.interceptors.request.use(function (config) {
  // TODO: when otcOnboarding needs access_token
  // const user = JSON.parse(localStorage.getItem('loggedInUser'));
  // config.headers.Authorization = user.access_token
  //   ? `${user.access_token}`
  //   : '';
  config.headers.common['x-api-key'] = apiKey;
  return config;
});

OTCOnboardInstance.interceptors.response.use(
  response => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      (originalRequest.url === '/auth/login' ||
        originalRequest.url === '/auth/refresh-token')
    ) {
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      let user = JSON.parse(localStorage.getItem('loggedInUser'));
      const res = await loginInstance.post('/auth/refresh-token', {
        refresh_token: user.refresh_token,
      });
      if (res.data.status === 'success') {
        user.access_token = res.data.response.access_token;

        localStorage.clear();
        localStorage.setItem('user', JSON.stringify(user));
        user = JSON.parse(localStorage.getItem('user'));
        originalRequest['headers']['Authorization'] = user.access_token;
        return axios(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

// user onboard instance
export const UserOnboardInstance = axios.create({
  baseURL: `${config.URL}`,
});

UserOnboardInstance.interceptors.request.use(function (config) {
  config.headers.common['x-api-key'] = apiKey;
  return config;
});

// check in instance
export const checkInInstance = axios.create({
  baseURL: `${config.URL}`,
});

checkInInstance.interceptors.request.use(function (config) {
  config.headers.common['x-api-key'] = apiKey;
  return config;
});

export const inAppInstance = axios.create({
  baseURL: `${config.URL}`,
});

const excludedURL = ['/opd/offline/cashless/invoice/upload'];

inAppInstance.interceptors.request.use(function (config) {
  const authToken = retrieveHelper(pc.authToken);

  if (!excludedURL.includes(config.url)) {
    config.headers.common['sessionId'] = authToken;
  }

  config.headers.common['x-api-key'] = apiKey;
  return config;
});

export const cashlessInstance = axios.create({
  baseURL: `${config.URL}`,
});

cashlessInstance.interceptors.request.use(function (config) {
  const sessionId = retrieveHelper('page_session');
  config.headers.common['sessionId'] = sessionId?.sess_id;
  config.headers.common['x-api-key'] = apiKey;
  return config;
});
