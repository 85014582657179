import React, { useContext, useState } from 'react';
import {
  Box,
  Image as ChakraImage,
  Button,
  Text,
  IconButton,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

import { px13, px14 } from '../../constants';
import { retrieveHelper, parseErrorMessage } from '../../../utils';
import { cashlessInstance } from './../../../api';
import { generatePdfFromImages } from './utils';
import { ColorContext } from '../../cashlessLayout';

const UploadView = ({
  capturedImages,
  setCapturedImages,
  setState,
  setErrorMessage,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const isSmallScreen = window.screen.width < 325;

  const handleImagesUpload = async () => {
    setIsLoading(true);
    let lastTransactionId = retrieveHelper('cashless_lastTransactionId');

    let pdfFile = await generatePdfFromImages(capturedImages);

    // console.log(pdfFile);

    let formData = new FormData();
    formData.append('file', pdfFile, `${lastTransactionId}.pdf`);
    formData.append('transaction_id', lastTransactionId);

    cashlessInstance
      .post('/opd/cashless/invoice/', formData)
      .then(response => {
        setIsLoading(false);
        let data = response.data;
        if (data.status === 'success') {
          //registerEvent(ec.InAppUpload, actions.BILL_UPLOAD_SUCCESS);
          setState('success');
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                type: 'INVOICE_UPLOAD_SUCCESS',
                payload: {
                  transacation_id: data.response.transaction_id,
                  invoice_upload_link: data.response.invoice_upload_link,
                  amount: retrieveHelper('inAppTotal'),
                  invoice_status: data.response.invoice_status,
                  invoice_uploaded_at: new Date().toLocaleString(),
                  invoice_link: data.response.url,
                },
              })
            );
          }
        } else {
          //registerEvent(ec.InAppUpload, actions.BILL_UPLOAD_FAILED);
          setErrorMessage(data.message);
          setState('fail');
        }
      })
      .catch(err => {
        //registerEvent(ec.InAppUpload, actions.BILL_UPLOAD_ERROR);
        setIsLoading(false);
        let errMsg = parseErrorMessage(err);
        setErrorMessage(errMsg);
      });
  };

  const { primary } = useContext(ColorContext);

  return (
    <Box
      bgColor="rgb(0,0,0,0.7)"
      className="containerHeight"
      display="flex"
      justifyContent="center"
      overflow="scroll"
    >
      <Box
        width="90%"
        display="flex"
        flexDir="column"
        justifyContent="space-between"
        alignItems="center"
        // overflow="scroll"
      >
        <Box
          display="flex"
          flexDir="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          // overflow="scroll"
        >
          <Text
            className="poppins fw-600"
            fontSize={px14}
            color="white"
            py={3}
            width="100%"
            textAlign="center"
          >
            Upload Prescription and Bills
          </Text>
          <Box
            height={isSmallScreen ? '65vh' : '75vh'}
            width="100%"
            overflow="scroll"
          >
            <Box display="flex" flexWrap="wrap" width="100%">
              {capturedImages.map((image, index) => (
                <Box
                  key={index}
                  height="100px"
                  width="100px"
                  p={1}
                  m={1}
                  border="1px solid white"
                  borderRadius={10}
                  position="relative"
                  style={{ overflow: 'hidden' }}
                >
                  <ChakraImage
                    key={index}
                    width={'100px'}
                    height={'100px'}
                    alt="bill"
                    objectFit="contain"
                    src={
                      image.startsWith('data:application/pdf')
                        ? 'https://cdn2.iconfinder.com/data/icons/file-8/128/file-expand_Pdf-512.png'
                        : image
                    }
                  />

                  <IconButton
                    position="absolute"
                    top="1"
                    right="1"
                    variant="solid"
                    size="xs"
                    colorScheme="red"
                    aria-label="Send email"
                    icon={<CloseIcon />}
                    onClick={() => {
                      let newCapturedImages = [...capturedImages];
                      newCapturedImages.splice(index, 1);
                      setCapturedImages(newCapturedImages);

                      if (newCapturedImages.length === 0) {
                        setState('capture');
                      }
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          flexDir="column"
          width="100%"
          mb="3vh"
          mt={5}
        >
          <Button
            mb={2}
            isFullWidth
            minH={['40px']}
            _active={{
              bgColor: { primary },
              color: 'white',
            }}
            _hover={{
              bgColor: { primary },
              color: 'white',
            }}
            bgColor={primary}
            color="white"
            variant="solid"
            isDisabled={isLoading}
            size="md"
            onClick={() => setState('capture')}
          >
            <Text className="poppins fw-600" fontSize={px13}>
              Attach more files
            </Text>
          </Button>

          <Button
            isLoading={isLoading}
            isFullWidth
            minH={['40px']}
            _active={{
              bgColor: { primary },
              color: 'white',
            }}
            _hover={{
              bgColor: { primary },
              color: 'white',
            }}
            bgColor={primary}
            color="white"
            variant="solid"
            size="md"
            onClick={handleImagesUpload}
          >
            <Text className="poppins fw-600" fontSize={px13}>
              Upload
            </Text>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UploadView;
