import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Box,
  Text,
  HStack,
  Button,
  Stack,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import Loader from '../../subComponents/loader';
import { retrieveHelper, pc, storeHelper } from '../../../utils';
import { cashlessInstance } from '../../../api/index';
import useAlertHook from '../../hooks/errorHook';
import AlertModal from '../../subComponents/alertModal/alertModal';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import config from '../../../../config';
import { CircleRightIcon } from '../../../../assets/icons';
import { ColorContext } from '../../cashlessLayout';

function PaymentDetail() {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const totalAmount = params.get('amount');
  if (!totalAmount) {
    history.push('/cashless/transaction');
  }
  storeHelper('cashlessTotal', totalAmount);
  const sumInsured = retrieveHelper('sessionData')?.sum_insured;

  // eslint-disable-next-line no-unused-vars
  const [toPay, setToPay] = useState(0);
  const [payVia, setPayVia] = useState('pg');
  const [isLoading, setIsLoading] = useState(true);
  //const [availableBalance, setAvailableBalance] = useState(0);
  const [hashedResponse, setHashedResponse] = useState({});
  const [errorLink, setErrorLink] = useState('');

  const { showAlertModal, setShowAlertModal, errorMessage, setErrorMessage } =
    useAlertHook();

  useEffect(() => {
    if (parseFloat(totalAmount) > parseFloat(sumInsured)) {
      setIsLoading(false);
      setToPay((totalAmount - sumInsured).toFixed(2));
    } else {
      handleTransactionCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTransactionCall = () => {
    let QRCode = '';
    let QRName = '';
    let location = '';

    try {
      QRCode = retrieveHelper(pc.qrCode);
      QRName = retrieveHelper(pc.qrName);
      location = retrieveHelper(pc.location);
    } catch (err) {
      //const inAppDataURL = JSON.parse(localStorage.getItem(pc.inAppDataURL));
      //history.push(`/partner/opd?data${inAppDataURL}`);
    }

    let payload = {
      payment_upi_id: QRCode,
      payment_coordinates: location,
      amount: totalAmount.toString(),
      patient_payable: toPay.toString(),
      provider_details: {
        provider_account_name: QRName,
        provider_id: retrieveHelper('cashless_providerId'),
      },
    };

    if (payVia === 'cash') {
      payload = { ...payload, patient_payable_mode: 'cash' };
    }

    storeHelper('cashless_paidAmount', totalAmount.toString());

    //registerEvent(ec.InAppTransaction, actions.PAYMENT_VALIDATE_IN_PROCESS);

    setIsLoading(true);

    cashlessInstance
      .post('/opd/cashless/transaction', payload)
      .then(response => {
        let data = response.data;
        if (data.status === 'success') {
          setIsLoading(false);
          //registerEvent(ec.InAppTransaction, actions.PAYMENT_VALIDATE_DONE);
          if (data.response.status === 'success') {
            //registerEvent(ec.InAppTransaction, actions.PAYMENT_STATUS_SUCCESS);
            storeHelper(
              'cashless_lastTransactionId',
              data.response.transaction_id
            );
            storeHelper('cashless_transactionStatus', 'success');
            storeHelper(
              'cashlessInvoiceLink',
              data.response.invoice_upload_link
            );
            storeHelper('cashlessInvoiceStatus', data.response.invoice_status);
          } else if (data.response.status === 'pending') {
            //registerEvent(ec.InAppTransaction, actions.PAYMENT_STATUS_PENDING);
            storeHelper(
              'cashless_lastTransactionId',
              data.response.transaction_id
            );
            storeHelper('cashless_transactionStatus', 'pending');
          } else {
            //registerEvent(ec.InAppTransaction, actions.PAYMENT_STATUS_FAILED);
            storeHelper('cashless_transactionStatus', 'fail');
          }
          history.push(
            `/cashless/transactionStatus/${data.response.transaction_id}`
          );
        } else {
          setShowAlertModal(true);
          if (
            data.message.includes('Please upload bill of last transaction.')
          ) {
            storeHelper(
              'cashless_lastTransactionId',
              data.response.last_transaction_id
            );
          }
          setIsLoading(false);
          setErrorMessage(data.message);
          setErrorLink('/cashless/transaction');
        }
      })
      .catch(err => {
        setIsLoading(false);
        setErrorMessage('Something went wrong.');
        setErrorLink('/cashless/transaction');
      });
  };

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  const obtainHashAndRedirect = () => {
    if (payVia === 'cash') {
      handleTransactionCall();
      return;
    }
    //registerEvent(ec.InAppPartialTransaction, actions.CONFIRM_AND_PAY);
    setIsLoading(true);
    const payload = {
      amount: toPay.toString(),
    };
    cashlessInstance
      .post('/opd/cashless/pg-data', payload)
      .then(response => {
        if (response.data.status === 'success') {
          const d = response.data.response;
          setHashedResponse(d);
          document.getElementById('loadPayU').click();
        } else {
          setIsLoading(false);
          setErrorMessage(response.data.message);
        }
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
        setErrorMessage('Something went wrong.');
      });
  };

  const { primary } = useContext(ColorContext);

  return (
    <Box
      style={{ backgroundColor: '#fff' }}
      margin="auto"
      className="containerHeight"
    >
      <Button
        id="tokenExpiredButton"
        display="none"
        onClick={() => setShowAlertModal(true)}
      ></Button>
      {isLoading && (
        <Box
          height={['62px']}
          position="fixed"
          bottom={0}
          maxW="500px"
          width="100%"
          borderTopRadius="10px"
          bgColor={primary}
          display="flex"
          justifyContent="center"
        >
          <HStack
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            width="60%"
          >
            <Loader mt={0} color="white" size="md" />
            <Text fontSize="13px" color="white">
              Fetching Data...
            </Text>
          </HStack>
        </Box>
      )}

      {!isLoading && (
        <Box
          display="flex"
          flexDir="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box px={4}>
            <HStack mt={5} justifyContent="space-between">
              <ChevronLeftIcon
                style={{ cursor: 'pointer', fontSize: '28px' }}
                onClick={() => window.history.back()}
              />
              <Text className="poppins fw-400">Payment Details</Text>
              <Text style={{ opacity: 0 }}></Text>
            </HStack>
            <Box
              style={{ marginTop: '35px' }}
              display="flex"
              justifyContent="space-between"
            >
              <Stack spacing={5} direction="column">
                <Text color="#6D8298" className="poppins fw-400">
                  Sum Insured
                </Text>
                <Text
                  color="#6D8298"
                  className="poppins fw-600"
                  //style={{ marginLeft: '25px', marginTop: '0' }}
                  style={{ marginTop: '0' }}
                >
                  {retrieveHelper('sessionData')?.partner_name}
                </Text>
              </Stack>
              <Text color={primary}>
                - &#8377;
                {Number(totalAmount) < Number(sumInsured) ? '--' : sumInsured}
              </Text>
            </Box>
            <Box
              style={{ marginTop: '35px' }}
              display="flex"
              justifyContent="space-between"
            >
              <Text className="poppins fw-400" color="#6D8298">
                Total Bill
              </Text>
              <Text className="poppins fw-400" color="#38434E">
                &#8377;
                {Number(totalAmount) < Number(sumInsured) ? '--' : totalAmount}
              </Text>
            </Box>
            <Box
              style={{ marginTop: '35px' }}
              display="flex"
              justifyContent="space-between"
            >
              <Text color="#6D8298" fontSize="24px" className="poppins fw-600">
                To Pay
              </Text>
              <Text color="#B94085" fontSize="24px" className="poppins fw-600">
                &#8377;{Number(totalAmount) < Number(sumInsured) ? '--' : toPay}
              </Text>
            </Box>
            <Box style={{ marginTop: '35px' }}>
              <Text>Pay via:</Text>
              <RadioGroup
                value={payVia}
                onChange={value => {
                  setPayVia(value);
                }}
              >
                <Stack spacing={5} direction="row">
                  <Radio colorScheme="blue" value="pg">
                    Card/UPI
                  </Radio>
                  {retrieveHelper('featureList')?.features_en?.pay_by_cash ? (
                    <Radio colorScheme="blue" value="cash">
                      Cash
                    </Radio>
                  ) : null}
                </Stack>
              </RadioGroup>
            </Box>
          </Box>
          <Box>
            <Button
              isLoading={isLoading}
              loadingText="processing"
              isFullWidth
              isDisabled={
                !payVia ||
                toPay === 0 ||
                Number(totalAmount) < Number(sumInsured)
              }
              minH={['40px']}
              _active={{
                color: 'white',
                background: { primary },
              }}
              _hover={{
                color: 'white',
                background: { primary },
              }}
              background={primary}
              color="white"
              variant="solid"
              size="md"
              borderRadius="0"
              style={{
                padding: '30px 0',
                display: 'flex',
                justifyContent: 'space-between',
              }}
              onClick={() => {
                obtainHashAndRedirect();
              }}
            >
              <Text></Text>
              <Text className="poppins fw-600" fontSize="16px">
                Confirm & Pay &#8377;{toPay}
              </Text>
              <CircleRightIcon
                style={{ marginRight: '10px' }}
                boxSize={['24px']}
              />
            </Button>
          </Box>
        </Box>
      )}

      <form action={config.PAYU_URL} method="post">
        <input type="hidden" name="key" value={hashedResponse.key} />
        <input
          type="hidden"
          name="txnid"
          value={hashedResponse.transaction_id}
        />
        <input
          type="hidden"
          name="productinfo"
          value={hashedResponse.product_info}
        />
        <input type="hidden" name="amount" value={hashedResponse.amount} />
        <input type="hidden" name="email" value={hashedResponse.email_id} />
        <input type="hidden" name="firstname" value={hashedResponse.name} />
        <input type="hidden" name="surl" value={hashedResponse.surl} />
        <input type="hidden" name="furl" value={hashedResponse.furl} />
        <input
          type="hidden"
          name="enforce_paymethod"
          value="creditcard|debitcard|UPI|lazypay"
        />
        <input type="hidden" name="hash" value={hashedResponse.hash} />
        <input
          style={{ display: 'none' }}
          type="submit"
          id="loadPayU"
          value="submit"
        />
      </form>

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          showUpload={false}
          onClose={() => {
            setShowAlertModal(false);
            if (errorMessage.includes('timed out')) {
              setTimeout(() => {
                document.getElementById('tokenExpiredButton').click();
              }, 0);
            } else {
              setErrorMessage('');
            }
          }}
          message={errorMessage}
          link={errorLink}
        />
      )}
    </Box>
  );
}

export default PaymentDetail;
