import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Text, HStack } from '@chakra-ui/react';
import { useHistory } from 'react-router';

import TransactionForm from './../../scanUI/transactionForm';
import useAlertHook from '../../hooks/errorHook';
import AlertModal from '../../subComponents/alertModal/alertModal';
import { px13 } from '../../constants';
import Loader from '../../subComponents/loader';
import './index.css';
import PoweredBy from '../../subComponents/poweredBy';
import { retrieveHelper } from '../../../utils';
import { ColorContext } from '../../cashlessLayout';

const Transaction = () => {
  const [amount, setAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { showAlertModal, setShowAlertModal, errorMessage, setErrorMessage } =
    useAlertHook();

  const history = useHistory();

  const handleLocationAndRedirect = () => {
    setIsLoading(true);
    history.push('/cashless/paymentDetail?amount=' + amount);
  };

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  const { primary } = useContext(ColorContext);
  const paymentComponent = (
    <>
      <Box
        display="flex"
        flexDir="column"
        flex={1}
        mt={10}
        height="100%"
        width="100%"
        margin="auto"
        justifyContent="space-between"
        alignItems="center"
      >
        <img
          alt="logo_black"
          src={retrieveHelper('featureList')?.logo_url}
          style={{ margin: '0 30%', width: '40%' }}
        />
        <Box
          width="100%"
          p={5}
          borderRadius="5px"
          bgColor="white"
          //boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
        >
          <TransactionForm
            isLoading={isLoading}
            amount={amount}
            setAmount={setAmount}
          />
        </Box>

        {!isLoading && (
          <Box width="100%">
            <PoweredBy logoType="black" />
            <Button
              isLoading={isLoading}
              loadingText="processing"
              isFullWidth
              minH={['40px']}
              _active={{
                color: 'white',
                background: { primary },
              }}
              _hover={{
                color: 'white',
                background: { primary },
              }}
              _disabled={{
                color: 'white',
                background: 'linear-gradient(360deg, #A3A3A3 0%, #A3A3A3 100%)',
              }}
              background={primary}
              color="white"
              variant="solid"
              size="md"
              disabled={Number(amount) === 0 || isNaN(Number(amount))}
              onClick={() => {
                handleLocationAndRedirect();
              }}
              mb="2vh"
            >
              <Text className="poppins fw-600" fontSize={px13}>
                Pay
              </Text>
            </Button>
          </Box>
        )}

        {isLoading && (
          <Box
            height={['62px']}
            position="fixed"
            bottom={0}
            maxW="500px"
            width="100%"
            borderTopRadius="10px"
            bgColor={primary}
            display="flex"
            justifyContent="center"
          >
            <HStack
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              width="60%"
            >
              <Loader mt={0} color="white" size="md" />
              <Text fontSize={px13} color="white">
                Fetching Data...
              </Text>
            </HStack>
          </Box>
        )}
      </Box>
    </>
  );

  return (
    <Box
      margin="auto"
      className="containerHeight"
      style={{ padding: '5% 5% 0 5%' }}
      bgColor="#FFF"
    >
      {paymentComponent}

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          showUpload={false}
          onClose={() => {
            if (errorMessage.includes('Please upload bill ')) {
              setErrorMessage('');
              setShowAlertModal(false);
              history.push('/upload');
            } else {
              setErrorMessage('');
              setShowAlertModal(false);
            }
          }}
          message={errorMessage}
        />
      )}
    </Box>
  );
};

export default Transaction;
