import { createIcon } from '@chakra-ui/react';

export const DoctorIcon = createIcon({
  displayName: 'DoctorIcon',
  viewBox: '0 0 29 33',
  path: [
    <path
      d="M13.7766 0.00391865C13.6866 0.032288 13.595 0.0551905 13.5023 0.0724984C10.6533 0.480051 8.34321 2.75686 8.06106 5.62344C7.92912 7.17387 7.91275 8.73196 8.01207 10.2848C8.16099 13.122 10.3927 15.5967 13.1398 16.1062C13.5585 16.1838 13.9836 16.2225 14.4095 16.2218V0.00391865H13.7766Z"
      fill="currentColor"
    />,
    <path
      d="M14.192 20.7479C12.6891 19.733 11.198 18.7023 9.88133 17.4542C9.8176 17.3827 9.7333 17.3326 9.63999 17.3108C9.54668 17.2891 9.44893 17.2967 9.36013 17.3327C5.45703 18.6181 2.43566 21.0007 0.280331 24.4963C0.224309 24.574 0.195153 24.6679 0.197275 24.7637C0.199396 24.8595 0.232678 24.952 0.292087 25.0272C3.57602 30.2098 8.34517 32.7531 14.3997 32.9961V20.8342C14.3243 20.8233 14.2529 20.7937 14.192 20.7479Z"
      fill="currentColor"
    />,
    <path
      d="M20.8539 9.73424C20.8617 8.65135 20.8617 7.56781 20.8539 6.48361C20.8636 5.02727 20.3784 3.61081 19.4778 2.46631C18.5771 1.32182 17.3145 0.51716 15.8967 0.18418C15.6184 0.113642 15.3363 0.0607381 15.0561 -2.86102e-06H14.4016V16.2178C17.9364 16.2159 20.8323 13.4434 20.8539 9.73424Z"
      fill="#1F2F91"
    />,
    <path
      d="M18.7534 32.3985C22.9348 31.2111 26.1991 28.7932 28.5092 25.0958C28.583 24.9997 28.6229 24.8819 28.6229 24.7608C28.6229 24.6396 28.583 24.5219 28.5092 24.4257C26.4636 21.0638 23.2759 18.5508 19.5294 17.3465C19.4266 17.3031 19.3127 17.2936 19.2042 17.3194C19.0957 17.3451 18.9982 17.4047 18.9259 17.4895C17.6165 18.7139 16.189 19.8054 14.6642 20.748C14.5893 20.8047 14.4976 20.835 14.4036 20.8342V33H14.4154C15.8815 32.9899 17.3399 32.7877 18.7534 32.3985V32.3985ZM16.4786 25.1213C16.4786 24.845 16.5981 24.7294 16.8705 24.7294C17.3525 24.7294 17.8364 24.7294 18.3185 24.7294C18.8005 24.7294 18.8024 24.7059 18.8024 24.2298C18.8024 23.7537 18.8122 23.2854 18.8024 22.8132C18.8024 22.5232 18.92 22.4036 19.2061 22.4076C19.6352 22.4076 20.0643 22.4174 20.4934 22.4076C20.7736 22.4076 20.8852 22.5232 20.8696 22.7994C20.8539 23.0757 20.8696 23.3363 20.8696 23.6047C20.8696 23.8732 20.8696 24.0985 20.8696 24.3454C20.8696 24.5923 20.9793 24.7373 21.2517 24.7373C21.7454 24.7373 22.2313 24.7373 22.733 24.7373C23.1248 24.7373 23.1875 24.8058 23.1895 25.1821C23.1915 25.5583 23.1895 25.9756 23.1895 26.3734C23.1895 26.7378 23.1287 26.7966 22.7545 26.7985C22.2705 26.7985 21.7885 26.7985 21.3046 26.7985C20.9617 26.7985 20.8735 26.8946 20.8715 27.2433C20.8715 27.7469 20.8715 28.2524 20.8715 28.756C20.8715 29.0146 20.7814 29.1283 20.513 29.1204C20.0741 29.1067 19.6332 29.1106 19.1943 29.1204C18.9239 29.1204 18.8024 29.0127 18.8024 28.7285C18.8122 28.2132 18.8024 27.6979 18.8024 27.1845C18.8024 26.8906 18.6829 26.7809 18.3968 26.7927C17.8933 26.8044 17.3877 26.7927 16.8842 26.7927C16.6001 26.7927 16.4688 26.6927 16.4766 26.4008C16.4903 25.9795 16.4884 25.5504 16.4786 25.1213V25.1213Z"
      fill="#1F2F91"
    />,
  ],
});

export const HospitalIcon = createIcon({
  displayName: 'HospitalIcon',
  viewBox: '0 0 30 30',
  path: [
    <path
      d="M13.6439 11.9179V9.00488H10.7252V6.08044H13.6295V3.15025H15.1061V0.200005H7.78638V10.4499H0.46666V29.5333H12.1902V22.2194H15.1061V11.9179H13.6439ZM3.40544 13.4231H6.30982V16.3189H3.40544V13.4231ZM6.30982 22.1764H3.40544V19.3092H6.30982V22.1764ZM12.173 16.3131H9.27726V13.4231H12.1673L12.173 16.3131Z"
      fill="currentColor"
    />,
    <path
      d="M18.0219 29.5333H29.7427V10.4556H22.4229V0.199997H15.1061V3.15024H16.5683V6.06322H19.4985V8.98767H16.5827V11.9178H15.1061V22.2193H18.0219V29.5333ZM26.7981 22.1878H23.9167V19.2949H26.7981V22.1878ZM23.9138 13.4231H26.8067V16.3275H23.9138V13.4231ZM18.042 13.4231H20.932V16.3189H18.042V13.4231Z"
      fill="#1F2F91"
    />,
  ],
});

export const PharmacyIcon = createIcon({
  displayName: 'PharmacyIcon',
  viewBox: '0 0 33 33',
  path: [
    <path
      d="M10.3627 10.3686C10.4252 10.2984 10.4856 10.2223 10.5578 10.154L18.2343 2.47754C19.3253 1.39177 20.694 0.627102 22.1905 0.267265C22.441 0.177357 22.7047 0.129885 22.9709 0.126806C22.9895 0.131009 23.0089 0.130861 23.0275 0.126371C23.0462 0.121881 23.0635 0.113171 23.0782 0.100914C23.0929 0.088657 23.1046 0.0731811 23.1124 0.0556831C23.1201 0.0381852 23.1238 0.0191349 23.123 3.8147e-06H25.4445C25.5498 0.25361 25.782 0.107297 25.9478 0.150214C26.8437 0.359958 27.7077 0.687972 28.517 1.12562C29.218 1.51343 29.8561 2.00544 30.4093 2.58483C30.3137 2.66872 30.2142 2.75065 30.1225 2.84039C25.6669 7.29475 21.2119 11.7485 16.7575 16.2015C16.6619 16.2971 16.5897 16.4337 16.4298 16.4434C16.3439 16.3654 16.2522 16.2893 16.1703 16.2074C14.5394 14.5778 12.9099 12.9476 11.2816 11.3167C10.9733 11.0006 10.669 10.6827 10.3627 10.3686Z"
      fill="#E4E4E4"
    />,
    <path
      d="M32.9883 25.2065C32.8108 25.6552 32.8108 26.1429 32.6742 26.6033C31.8458 29.3734 30.0972 31.3067 27.4285 32.4031C26.429 32.7985 25.3625 32.9973 24.2877 32.9883V15.6104C25.8017 15.6015 27.2911 15.9932 28.6048 16.7458L28.7999 16.8687C29.7811 17.4606 30.6351 18.2412 31.3126 19.1655C31.99 20.0897 32.4773 21.1391 32.7464 22.253C32.8498 22.6548 32.8478 23.0762 32.9961 23.4664L32.9883 25.2065Z"
      fill="#1F2F91"
    />,
    <path
      d="M28.6048 16.7458C27.3003 16.0082 25.8284 15.6173 24.3298 15.6105C22.8312 15.6037 21.3558 15.9811 20.0446 16.7068C19.3552 17.1185 18.7082 17.5975 18.1133 18.1367L16.4317 16.4883V16.4376C16.5917 16.4278 16.6638 16.2913 16.7594 16.1957C21.2151 11.7439 25.6701 7.29021 30.1245 2.83455C30.2162 2.74481 30.3196 2.66288 30.4113 2.57899C30.6107 2.74004 30.7884 2.92627 30.9399 3.13302C31.9535 4.31632 32.6176 5.75853 32.8576 7.29801C32.8712 7.3858 32.8693 7.47359 32.9883 7.47944V9.93552C32.762 9.99209 32.881 10.1833 32.8537 10.3101C32.485 11.9995 31.7632 13.5016 30.5361 14.7404C29.9626 15.3256 29.3832 15.8953 28.8077 16.4727C28.7277 16.5488 28.6282 16.6132 28.6048 16.7458Z"
      fill="#D8D8D8"
    />,
    <path
      d="M16.4317 16.4376V16.4883C16.34 16.5936 16.2542 16.7029 16.1567 16.8004C11.714 21.247 7.2707 25.6916 2.82674 30.1342C2.7292 30.2318 2.64337 30.343 2.55168 30.4464C0.536487 28.3668 -0.300418 25.8873 0.0955975 23.006C0.351595 21.1515 1.21861 19.4353 2.55948 18.1289C5.09554 15.5812 7.63746 13.0386 10.1852 10.5012C10.241 10.4521 10.3003 10.4071 10.3627 10.3666C10.669 10.6807 10.9734 10.9987 11.2835 11.3089C12.9105 12.9398 14.5401 14.57 16.1723 16.1996C16.2542 16.2835 16.3381 16.3595 16.4317 16.4376Z"
      fill="currentColor"
    />,
    <path
      d="M18.1133 18.1289C18.7083 17.5896 19.3552 17.1107 20.0446 16.699C21.3389 15.9692 22.8018 15.5912 24.2877 15.6026V32.9942C21.1352 32.9187 18.6856 31.5681 16.939 28.9423C16.8609 28.8272 16.7848 28.7102 16.7088 28.5951C16.5976 28.3629 16.4766 28.1366 16.3752 27.9006C15.2047 25.1928 15.3302 22.5462 16.7517 19.9608C17.1184 19.2877 17.6315 18.7239 18.1133 18.1289Z"
      fill="currentColor"
    />,
    <path
      d="M18.1133 18.1289C17.6315 18.7142 17.1203 19.2799 16.7477 19.9529C15.3275 22.5384 15.202 25.185 16.3712 27.8928C16.4727 28.1288 16.5936 28.3551 16.7048 28.5873C16.1508 29.1393 15.589 29.6836 15.0427 30.2454C13.7532 31.57 12.2297 32.485 10.4057 32.8205C7.38579 33.3746 4.74828 32.5981 2.54776 30.4386C2.63945 30.3352 2.72528 30.224 2.82282 30.1264C7.26678 25.6825 11.7101 21.2379 16.1527 16.7926C16.2503 16.6951 16.3361 16.5858 16.4278 16.4805L18.1133 18.1289Z"
      fill="#1F2F91"
    />,
  ],
});

export const DentistIcon = createIcon({
  displayName: 'DentistIcon',
  viewBox: '0 0 33 33',
  path: [
    <path
      d="M27.7853 3.53437e-06V15.6025V16.039C27.1335 15.7446 26.529 15.4773 25.9348 15.193C25.8738 15.1643 25.8535 15.0086 25.8535 14.9105C25.8535 14.1373 25.8535 13.3641 25.8535 12.5892V1.95073C25.735 1.95073 25.6317 1.9355 25.5301 1.9355C24.1198 1.9355 22.7111 1.9355 21.2974 1.9355C21.1232 1.94547 20.9577 2.01473 20.8284 2.13176C20.2511 2.67655 19.694 3.24502 19.1217 3.81349C19.1675 3.88285 19.1979 3.93699 19.2369 3.98267C20.0879 5.03664 20.566 6.34238 20.5966 7.6963C20.6272 9.05021 20.2087 10.3762 19.4062 11.4675C19.3647 11.5186 19.3134 11.5609 19.2552 11.5917C19.197 11.6225 19.1332 11.6413 19.0676 11.6469C18.3362 11.6615 17.6025 11.6615 16.8666 11.6469C16.7326 11.6385 16.6059 11.5827 16.5093 11.4895C14.4234 9.34648 12.3426 7.20344 10.2669 5.0604C10.1643 4.94453 10.1046 4.79704 10.0976 4.6425C10.079 3.99959 10.0976 3.3533 10.084 2.70869C10.079 2.63055 10.0966 2.55262 10.1348 2.48422C10.1729 2.41581 10.2299 2.35982 10.2991 2.32295C12.6389 0.759654 15.27 0.778265 17.5641 2.37878C17.6064 2.40754 17.6522 2.43292 17.7199 2.47521C18.4547 1.73925 19.1827 1.01174 19.909 0.275777C19.9988 0.186108 20.0783 0.084596 20.1579 -0.0118408L27.7853 3.53437e-06Z"
      fill="#1F2F91"
    />,
    <path
      d="M32.1501 26.7756L27.4094 24.6235C26.3055 24.116 25.2084 23.6084 24.096 23.1195C23.9284 23.0514 23.7487 23.018 23.5678 23.0213C19.5044 23.0213 15.441 23.0213 11.3776 23.0213C11.1652 23.0285 10.9584 22.9533 10.8002 22.8115C7.25377 19.8147 3.71069 16.8201 0.171003 13.8277C0.113438 13.7803 0.0507933 13.7397 0.00169373 13.7008C2.04525 10.5844 5.64307 10.5201 7.78991 12.3693C9.49315 13.8345 11.2218 15.2709 12.9318 16.7293C13.1219 16.8994 13.371 16.9887 13.626 16.978C17.3575 16.9712 21.0903 16.9712 24.8241 16.978C25.0152 16.9754 25.2049 17.0123 25.3811 17.0862C26.9388 17.7833 28.4964 18.482 30.0439 19.2028C31.2597 19.7524 32.2082 20.761 32.6816 22.0077C33.155 23.2545 33.1149 24.638 32.57 25.8552C32.4362 26.1496 32.2991 26.4372 32.1501 26.7756Z"
      fill="currentColor"
    />,
    <path
      d="M7.9643 33C7.66801 30.2169 9.12914 27.7129 12.07 27.0158C11.8161 26.3391 11.5621 25.6776 11.2929 24.967C11.4385 24.9585 11.5486 24.9484 11.6603 24.9484C15.3275 24.9484 18.9948 24.9484 22.6654 24.9399C22.7684 24.9235 22.8738 24.9476 22.9594 25.0073C23.0449 25.0669 23.104 25.1574 23.1242 25.2597C23.2834 25.7266 23.4899 26.1767 23.644 26.6453C23.663 26.7479 23.7215 26.8389 23.8071 26.8987C23.8926 26.9585 23.9982 26.9824 24.1011 26.9651C25.1119 26.9709 26.098 27.278 26.9332 27.847C27.7683 28.4161 28.4146 29.2212 28.7893 30.1593C29.1301 31.0632 29.2463 32.0363 29.1279 32.9949L7.9643 33Z"
      fill="currentColor"
    />,
  ],
});

export const OthersIcon = createIcon({
  displayName: 'OthersIcon',
  viewBox: '0 0 30 30',
  path: [
    <path
      d="M14.0084 6.99067C14.0084 8.0708 14.0084 9.15092 14.0084 10.2329C14.0084 12.2874 12.5562 13.7339 10.4998 13.7377C8.32449 13.7377 6.15038 13.7377 3.97755 13.7377C1.92682 13.7377 0.474701 12.2799 0.470924 10.2292C0.470924 8.05632 0.470924 5.88285 0.470924 3.70875C0.470924 1.65802 1.9306 0.205895 3.98133 0.202118C6.15416 0.202118 8.32827 0.202118 10.5036 0.202118C12.5562 0.202118 14.0027 1.65424 14.0084 3.71064C14.0102 4.80398 14.0084 5.89733 14.0084 6.99067Z"
      fill="currentColor"
    />,
    <path
      d="M29.8019 6.98665C29.8019 8.06677 29.8019 9.14879 29.8019 10.2289C29.8019 12.2853 28.3498 13.7337 26.2953 13.7374C24.1212 13.7374 21.9477 13.7374 19.7749 13.7374C17.7222 13.7374 16.2682 12.2815 16.2645 10.2308C16.2645 8.0567 16.2645 5.88323 16.2645 3.71039C16.2645 1.65967 17.7204 0.20565 19.7711 0.201874C21.9439 0.201874 24.118 0.201874 26.2934 0.201874C28.3498 0.201874 29.7962 1.654 29.8019 3.70851C29.8019 4.80185 29.8019 5.8933 29.8019 6.98665Z"
      fill="#1F2F91"
    />,
    <path
      d="M14.0079 22.7599C14.0079 23.8513 14.0079 24.9447 14.0079 26.038C14.0079 28.0718 12.552 29.5258 10.522 29.5295C8.33535 29.5295 6.14992 29.5295 3.96575 29.5295C1.93391 29.5295 0.476116 28.0699 0.470451 26.0437C0.470451 23.8583 0.470451 21.6735 0.470451 19.4893C0.470451 17.4574 1.93013 15.9997 3.95819 15.9959C6.14362 15.9959 8.32905 15.9959 10.5145 15.9959C12.5482 15.9959 14.0003 17.4499 14.0079 19.4817C14.0117 20.5732 14.0079 21.6665 14.0079 22.7599Z"
      fill="currentColor"
    />,
    <path
      d="M16.2644 22.7372C16.2701 21.3984 16.6725 20.0913 17.4209 18.9812C18.1694 17.8711 19.2302 17.0079 20.4692 16.5006C21.7082 15.9934 23.0698 15.8649 24.3818 16.1315C25.6938 16.398 26.8974 17.0476 27.8402 17.9981C28.7831 18.9486 29.4229 20.1574 29.6789 21.4715C29.9348 22.7856 29.7953 24.1462 29.2781 25.381C28.7608 26.6159 27.889 27.6697 26.7729 28.4091C25.6568 29.1485 24.3465 29.5404 23.0077 29.5352C22.1185 29.5317 21.2387 29.3531 20.4186 29.0096C19.5985 28.6661 18.854 28.1643 18.2278 27.5331C17.6017 26.9018 17.106 26.1533 16.7691 25.3305C16.4322 24.5076 16.2607 23.6264 16.2644 22.7372Z"
      fill="#1F2F91"
    />,
  ],
});

export const LabIcon = createIcon({
  displayName: 'LabIcon',
  viewBox: '0 0 29 33',
  path: [
    <path
      d="M12.2954 -7.62939e-06C12.8827 0.229983 13.0563 0.801342 13.3832 1.30182L8.41018 4.29025C8.21635 3.95901 8.01096 3.65814 7.85908 3.33268C7.7624 3.13195 7.74365 2.90247 7.80644 2.6887C7.86924 2.47493 8.00911 2.29206 8.199 2.1755C9.40681 1.44069 10.6276 0.72902 11.8441 0.00577953L12.2954 -7.62939e-06Z"
      fill="#1F2F91"
    />,
    <path
      d="M10.7 23.2681C9.10885 24.8925 8.53026 26.8018 8.69661 29.0395C8.2265 28.9426 7.80847 28.8804 7.39478 28.7705C5.42017 28.245 3.69319 27.042 2.51596 25.3719C1.33873 23.7017 0.786204 21.6709 0.955042 19.6345C1.28484 15.5135 4.59149 12.1316 8.70673 11.7281C9.16311 11.691 9.60332 11.542 9.98831 11.2941C10.7477 10.8081 11.5216 10.3452 12.3027 9.89103C13.3948 9.25458 14.5172 9.22421 15.6064 9.86644C16.6956 10.5087 17.2294 11.5039 17.1817 12.7681C17.1778 13.2664 17.0482 13.7556 16.805 14.1905C16.5618 14.6254 16.2127 14.9919 15.7901 15.256C14.6633 15.9793 13.5004 16.6519 12.3432 17.3288C12.1637 17.4189 11.9654 17.465 11.7646 17.4634C11.0775 17.4778 10.389 17.4634 9.70191 17.4634C8.03267 17.4749 6.74965 18.729 6.74386 20.3563C6.73807 21.9836 8.02544 23.258 9.71059 23.2695L10.7 23.2681Z"
      fill="currentColor"
    />,
    <path
      d="M22.3673 16.2078L23.7154 18.4585C24.1624 19.202 24.051 19.6765 23.3162 20.1205C22.3258 20.7194 21.3326 21.3158 20.3364 21.9098C19.6913 22.2946 19.1792 22.1644 18.7916 21.5207C18.3808 20.8409 17.9714 20.1606 17.5635 19.4798C17.51 19.3901 17.4492 19.3033 17.3827 19.202C17.0934 19.3698 16.8374 19.5333 16.5683 19.6837C16.4574 19.7561 16.3327 19.8047 16.202 19.8265C16.0713 19.8483 15.9376 19.8428 15.8091 19.8103C15.6807 19.7778 15.5604 19.7191 15.4558 19.6378C15.3512 19.5565 15.2646 19.4544 15.2014 19.338C14.9859 18.9937 14.7819 18.6422 14.5621 18.2734C15.2578 17.8539 15.9391 17.4503 16.6146 17.0367C17.2322 16.6739 17.7662 16.1847 18.1815 15.6011C18.5968 15.0175 18.884 14.3528 19.0245 13.6504C19.645 10.7835 17.6098 7.91225 14.6995 7.53183C13.5088 7.36485 12.2972 7.62079 11.2757 8.25507L9.26362 9.47156C8.89476 8.84668 8.51001 8.25362 8.18889 7.6273C7.95745 7.17455 8.17008 6.69142 8.65465 6.39924C10.05 5.55931 11.4483 4.71987 12.8494 3.88091C13.5944 3.43395 14.3364 2.9841 15.0828 2.53858C15.7193 2.1596 16.227 2.28496 16.6059 2.91467C18.8789 6.69287 21.1508 10.4711 23.4218 14.2493C23.8167 14.9089 23.685 15.4065 23.0182 15.8158C22.8056 15.9388 22.5973 16.0675 22.3673 16.2078Z"
      fill="#1F2F91"
    />,
    <path
      d="M10.6103 31.0675C10.6103 30.0144 10.5973 29.0062 10.6103 27.9981C10.6161 27.3638 10.7467 26.7369 10.9948 26.1531C11.2429 25.5694 11.6035 25.0402 12.0561 24.5958C12.5087 24.1514 13.0444 23.8005 13.6326 23.5631C14.2207 23.3258 14.8499 23.2066 15.4842 23.2124C16.1185 23.2182 16.7454 23.3488 17.3291 23.5969C17.9129 23.845 18.4421 24.2056 18.8865 24.6582C19.3309 25.1108 19.6817 25.6465 19.9191 26.2346C20.1565 26.8228 20.2757 27.452 20.2699 28.0863C20.2844 29.0627 20.2699 30.0405 20.2699 31.0675H20.6228C21.4386 31.0675 22.2545 31.0675 23.0717 31.0675C23.6894 31.0675 24.1363 31.4797 24.1349 32.0352C24.1334 32.5906 23.6879 33 23.0674 33H7.80122C7.172 33 6.74529 32.6065 6.7424 32.0366C6.73951 31.4667 7.16765 31.0704 7.79542 31.0675C8.71828 31.0646 9.6397 31.0675 10.6103 31.0675ZM14.4767 28.1167C14.4802 28.3705 14.5835 28.6128 14.7644 28.791C14.9453 28.9692 15.1891 29.0689 15.443 29.0685C15.5699 29.0666 15.6951 29.0397 15.8116 28.9894C15.9282 28.9391 16.0336 28.8663 16.122 28.7752C16.2104 28.6841 16.2799 28.5766 16.3267 28.4586C16.3735 28.3406 16.3967 28.2146 16.3948 28.0877C16.3929 27.9608 16.366 27.8356 16.3157 27.7191C16.2654 27.6026 16.1926 27.4971 16.1015 27.4087C16.0105 27.3203 15.9029 27.2508 15.7849 27.204C15.667 27.1572 15.5409 27.1341 15.414 27.136C15.1602 27.1432 14.9194 27.2502 14.7439 27.4338C14.5684 27.6174 14.4724 27.8627 14.4767 28.1167Z"
      fill="currentColor"
    />,
    <path
      d="M22.2241 27.4527C22.0577 26.7888 21.9001 26.1509 21.7337 25.4913C22.4468 25.0574 23.1802 24.6104 23.928 24.1591C24.9656 23.5304 26.0018 22.9002 27.0365 22.2685C27.1963 22.1566 27.3867 22.0965 27.5818 22.0965C27.777 22.0965 27.9674 22.1566 28.1272 22.2685C28.2785 22.3641 28.3983 22.5023 28.4714 22.6657C28.5444 22.8291 28.5676 23.0105 28.538 23.1871C28.4946 23.5646 28.2559 23.7917 27.9464 23.9783C26.3118 24.9667 24.6788 25.9576 23.0471 26.9508L22.2241 27.4527Z"
      fill="#1F2F91"
    />,
  ],
});

export const StarIcon = createIcon({
  displayName: 'StarIcon',
  viewBox: '0 0 12 11',
  path: [
    <path
      d="M6 0L7.83389 3.47587L11.7063 4.1459L8.9673 6.96413L9.52671 10.8541L6 9.12L2.47329 10.8541L3.0327 6.96413L0.293661 4.1459L4.16611 3.47587L6 0Z"
      fill="currentColor"
    />,
  ],
});

export const UploadIcon = createIcon({
  displayName: 'UploadIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 2L6.46 7.46L7.88 8.88L11 5.75V15H13V5.75L16.13 8.88L17.55 7.45L12 2Z"
      fill="currentColor"
    />,
  ],
});
