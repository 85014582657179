import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';

import TransactionStatus from '../../scanUI/transactionsUI/transactionStatus';
import { retrieveHelper, pc } from '../../../utils';
import { primaryText, px16 } from './../../constants';
import PoweredBy from '../../subComponents/poweredBy';

const FailedTrasaction = ({ state, transaction }) => {
  const paidAmount = retrieveHelper('cashless_paidAmount')
    ? retrieveHelper('cashless_paidAmount')
    : transaction.payment_amount;
  const qrName = retrieveHelper(pc.qrName);

  return (
    <Box
      display="flex"
      flexDir="column"
      flex={1}
      className="containerHeight"
      height="100%"
      width="100%"
      margin="auto"
      justifyContent="space-between"
      alignItems="center"
      style={{ padding: '5% 5% 0 5%' }}
    >
      <img
        src={retrieveHelper('featureList')?.logo_url}
        alt="logo_black"
        style={{ margin: '0 30%', width: '40%' }}
      />
      <Box width="100%" p={5} borderRadius="5px" bgColor="white">
        <TransactionStatus state={state} transaction={transaction} />

        <Stack color={primaryText} textAlign="center">
          <Stack spacing={0} mt={5} fontSize={px16} fontWeight="bold">
            <Text className="poppins fw-500">Payment Failed </Text>
            <Text className="poppins fw-500">of &#8377;{paidAmount}</Text>
          </Stack>
          <Stack spacing={0} pt={5} fontSize={px16}>
            <Text className="poppins fw-400">Payment to {qrName} failed. </Text>
          </Stack>
        </Stack>
      </Box>
      <PoweredBy logoType="black" />
    </Box>
  );
};

export default FailedTrasaction;
