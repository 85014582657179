import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

import useAlertHook from '../../hooks/errorHook';
import AlertModal from './../../subComponents/alertModal/alertModal';
import SuccessUpload from './successUpload';
import FailUpload from './failUpload';
import { retrieveHelper, storeHelper } from '../../../utils';

import './index.css';
import UploadView from './uploadView';
import CaptureView from './captureView';
import { cashlessInstance } from '../../../api';
import { ColorContext } from '../../cashlessLayout';

const WebCamUIMultiple = () => {
  const [capturedImages, setCapturedImages] = useState([]);
  const [state, setState] = useState('capture');

  const location = useLocation();

  const { showAlertModal, setShowAlertModal, errorMessage, setErrorMessage } =
    useAlertHook();

  const history = useHistory();

  // const dataUrlToFile = async (dataUrl, fileName) => {
  //   const res = await fetch(dataUrl);
  //   const blob = await res.blob();
  //   return new File([blob], fileName, { type: 'image/png' });
  // };

  // const handleUploadImage = async () => {
  //   let lastTransactionId = retrieveHelper(pc.lastTransactionId);

  //   registerEvent(ec.InAppUpload, actions.BILL_UPLOAD_INIT);

  //   setIsLoading(true);
  //   let formData = new FormData();
  //   let file = await dataUrlToFile(capturedImage, 'bill.png');
  //   formData.append('file', file);
  //   formData.append('transaction_id', lastTransactionId);

  //   inAppInstance
  //     .post('/opd/offline/cashless/invoice/upload', formData)
  //     .then(response => {
  //       setIsLoading(false);
  //       let data = response.data;
  //       if (data.status === 'success') {
  //         registerEvent(ec.InAppUpload, actions.BILL_UPLOAD_SUCCESS);
  //         setState('success');
  //       } else {
  //         registerEvent(ec.InAppUpload, actions.BILL_UPLOAD_FAILED);
  //         setErrorMessage(data.message);
  //         setState('fail');
  //       }
  //     })
  //     .catch(err => {
  //       registerEvent(ec.InAppUpload, actions.BILL_UPLOAD_ERROR);
  //       setIsLoading(false);
  //       const errMsg = parseErrorMessage(err);
  //       setErrorMessage(errMsg);
  //     });
  // };

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const transactionId = params.get('transaction_id');
    const sessionId = params.get('session_id');
    const partnerName = params.get('partner_name');

    if (transactionId != null && sessionId != null && partnerName != null) {
      storeHelper('cashless_lastTransactionId', transactionId);
      storeHelper('page_session', {
        page: '',
        sess_id: sessionId,
      });
      getFeaturesList(partnerName);
    }

    let lastTransactionId = retrieveHelper('cashless_lastTransactionId');

    try {
      if (lastTransactionId == null || lastTransactionId === 'null') {
        history.push('/cashless/opd');
      }
    } catch (error) {
      history.push('/cashless/opd');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { setPrimary } = useContext(ColorContext);
  const getFeaturesList = partner => {
    cashlessInstance.get(`/opd/cashless/features/${partner}`).then(response => {
      if (response.data.status === 'success') {
        storeHelper('featureList', response.data.response);
        setPrimary(response.data.response?.theme?.primary_color);
      }
    });
  };

  const decideView = state => {
    if (state === 'capture') {
      return (
        <CaptureView
          capturedImages={capturedImages}
          setCapturedImages={setCapturedImages}
          setState={setState}
        />
      );
    } else if (state === 'upload') {
      return (
        <UploadView
          capturedImages={capturedImages}
          setCapturedImages={setCapturedImages}
          setErrorMessage={setErrorMessage}
          setState={setState}
        />
      );
    } else if (state === 'success') {
      return <SuccessUpload />;
    } else {
      return <FailUpload setState={setState} />;
    }
  };

  return (
    <Box bgColor="rgb(0,0,0,0.7)" className="containerHeight" overflow="scroll">
      {decideView(state)}

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          onClose={() => {
            setErrorMessage('');
            setShowAlertModal(false);
          }}
          message={errorMessage}
        />
      )}
    </Box>
  );
};

export default WebCamUIMultiple;
