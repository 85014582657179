import React, { useEffect, useState, useRef, useContext } from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router';
import QrReader from 'react-qr-reader';

import {
  storeHelper,
  pc,
  parseErrorMessage,
  registerEvent,
  ec,
  actions,
  retrieveHelper,
} from '../../../utils';
import { cashlessInstance } from '../../../api';
import useAlertHook from '../../hooks/errorHook';
import './index.css';
import { TorchIcon } from '../../../../assets/icons';
// import { BackButton } from '../../subComponents/button';
import QRErrorModal from '../../subComponents/alertModal/qrErrorModal';
import Loader from './../../subComponents/loader';
import {
  px13,
  //  px13,
  px14,
  //  px16,
} from '../../constants';
import PoweredBy from '../../subComponents/poweredBy';
import { BackButton } from '../../subComponents/button';
import { ColorContext } from '../../cashlessLayout';

const ScannerCamera = () => {
  const qrReader = useRef(null);
  const history = useHistory();

  const [result, setResult] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [supported, setSupported] = useState(true);

  const { showAlertModal, setShowAlertModal, errorMessage, setErrorMessage } =
    useAlertHook();

  useEffect(() => {
    // console.log('mounted');
    setIsMounted(true);

    return () => {
      // console.log('unmounted');
      // qrReader.current.stopCamera();
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.removeItem('qrName');
    localStorage.removeItem('qrCode');
  }, []);

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useEffect(() => {
    if (result != null) {
      let extractedCode = result;

      setIsLoading(true);

      registerEvent(ec.Scanner, actions.QR_VALIDATE_INIT);

      const payload = {
        payment_qr_code: extractedCode,
        is_scanned: true,
      };

      cashlessInstance
        .post('/opd/cashless/validate-upi', payload)
        .then(response => {
          sessionStorage.removeItem('inAppLocationAsked');
          setIsLoading(false);
          let data = response.data;
          if (data.status === 'success') {
            registerEvent(ec.Scanner, actions.QR_VALIDATE_SUCCESS);
            storeHelper(pc.paymentType, 'scan');
            if (data.response.provider_account_name == null) {
              storeHelper(pc.qrName, '');
            } else {
              storeHelper(pc.qrName, data.response.provider_account_name);
            }
            storeHelper(pc.qrCode, data.response.payment_upi_id);
            history.push('/cashless/transaction');
          } else {
            setErrorMessage(data.message);
          }
        })
        .catch(err => {
          setIsLoading(false);
          const errMsg = parseErrorMessage(err);
          setErrorMessage(errMsg);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const { primary } = useContext(ColorContext);
  return (
    <>
      <Box display="flex" justifyContent="center" className="containerHeight">
        <Box
          width="100%"
          overflow="clip"
          position="relative"
          bgColor="rgba(0,0,0,0.8)"
          maxWidth="500px"
        >
          <Box backgroundColor="#fff">
            <img
              alt="logo"
              src={retrieveHelper('featureList')?.logo_url}
              style={{
                width: '40%',
                margin: '0 30%',
                padding: '10px 0',
                maxHeight: '60px',
              }}
            />
          </Box>
          {isMounted &&
            (isLoading ? (
              <Loader mt="45%" color="white" />
            ) : (
              <Box>
                <QrReader
                  ref={qrReader}
                  delay={10}
                  legacyMode={!supported}
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  facingMode="environment"
                  showViewFinder={false}
                  onError={err => {
                    setSupported(false);
                  }}
                  onScan={data => {
                    setResult(data);
                  }}
                  className="scannerParent"
                />
              </Box>
            ))}

          <Box
            className="element"
            height="100%"
            maxWidth="500px"
            marginTop="12%"
          >
            <Box width="90%" height="40%" marginTop="5%">
              <Box display="flex" justifyContent="space-between" height="100%">
                <Box
                  display="flex"
                  flexDir="column"
                  justifyContent="space-between"
                >
                  <Box
                    w="45px"
                    h="37px"
                    borderLeft={`5px solid ${primary}`}
                    borderTop={`5px solid ${primary}`}
                    borderTopLeftRadius="25%"
                  ></Box>
                  <Box
                    w="45px"
                    h="37px"
                    borderLeft={`5px solid ${primary}`}
                    borderBottom={`5px solid ${primary}`}
                    borderBottomLeftRadius="25%"
                  ></Box>
                </Box>
                <Box
                  display="flex"
                  flexDir="column"
                  justifyContent="space-between"
                >
                  <Box
                    w="45px"
                    h="37px"
                    borderRight={`5px solid ${primary}`}
                    borderTop={`5px solid ${primary}`}
                    borderTopRightRadius="25%"
                  ></Box>
                  <Box
                    w="45px"
                    h="37px"
                    borderRight={`5px solid ${primary}`}
                    borderBottom={`5px solid ${primary}`}
                    borderBottomRightRadius="25%"
                  ></Box>
                </Box>
              </Box>
              {retrieveHelper('featureList')?.features_en
                ?.force_scanpay ? null : (
                <Stack textAlign="center">
                  <Text fontSize={px13} color="#fff">
                    QR code not available?
                  </Text>
                  <Text
                    as="button"
                    color="#fff"
                    fontSize={px14}
                    textDecoration="underline"
                    fontWeight="bold"
                    onClick={() => {
                      registerEvent(ec.Scanner, actions.MANUAL_PAYMENT_METHOD);
                      history.push('/cashless/pay');
                    }}
                  >
                    Click here for more options
                  </Text>
                </Stack>
              )}

              <Stack alignItems="center" mt={4}>
                <BackButton
                  onClick={() => {
                    registerEvent(ec.Scanner, actions.BACK_NAVIGATION);
                    history.goBack();
                  }}
                />
              </Stack>
            </Box>
          </Box>

          <Box
            height="auto"
            color="white"
            style={{
              position: 'fixed',
              bottom: '0',
              backgroundColor: 'transparent',
              width: '100%',
              maxWidth: '500px',
            }}
          >
            <Stack display="flex" justifyContent="center" alignItems="center">
              <PoweredBy logoType="white" />
              <TorchIcon boxSize={10} display="none" />
              <Box
                style={{
                  backgroundColor: '#38434E',
                  width: '100%',
                  textAlign: 'center',
                  marginTop: 0,
                }}
              >
                <Text className="poppins fw-500" fontSize="18px" color>
                  Scan any QR code to pay
                </Text>
                <Text
                  className="poppins fw-500"
                  fontSize={px14}
                  style={{ marginBottom: '8px' }}
                >
                  Google Pay . PhonePe . PayTM . UPI{' '}
                </Text>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>

      {showAlertModal && (
        <QRErrorModal
          isOpen={showAlertModal}
          message={errorMessage}
          onClose={() => {
            setErrorMessage('');
            setShowAlertModal(false);
          }}
        />
      )}
    </>
  );
};

export default ScannerCamera;
