import React, { useEffect } from 'react';
import { Box, Button } from '@chakra-ui/react';
import ScannerCamera from '../../scanUI/scannerCamera';
import useAlertHook from '../../../scan-inApp/hooks/errorHook';
import AlertModal from '../../../scan-inApp/subComponents/alertModal/alertModal';

const Scanner = () => {
  const { showAlertModal, setShowAlertModal, errorMessage, setErrorMessage } =
    useAlertHook();

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  return (
    <Box>
      <Button
        id="scannerError"
        display="none"
        onClick={() => setShowAlertModal(true)}
      ></Button>
      {errorMessage.trim() === '' && <ScannerCamera />}
      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          message={errorMessage}
          onClose={() => {
            if (errorMessage.includes('enable location')) {
              window.location.reload();
              return;
            }
            setShowAlertModal(false);
            setTimeout(() => {
              document.getElementById('scannerError').click();
            }, 0);
          }}
        />
      )}
    </Box>
  );
};

export default Scanner;
