import React, { useState } from 'react';
import { retrieveHelper } from '../utils';

export const ColorContext = React.createContext({
  primary: '',
  setPrimary: () => {},
});

function CashlessLayout(props) {
  const [primary, setPrimary] = useState(
    retrieveHelper('featureList')
      ? retrieveHelper('featureList').theme?.primary_color
      : '#333'
  );
  const value = { primary, setPrimary };

  return (
    <ColorContext.Provider value={value}>
      {props.children}
    </ColorContext.Provider>
  );
}

export default CashlessLayout;
