import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

import useAlertHook from '../../hooks/errorHook';
import AlertModal from '../../subComponents/alertModal/alertModal';
import CheckIn from '../checkIn';
import Scanner from './../scanner';
import Success from './../success';
import Cancel from './../cancel';
import Loader from '../../subComponents/loader';
import { storeHelper, pc, retrieveHelper } from '../../../utils';
import { useLocation } from 'react-router';
import { checkInInstance } from '../../../api';
import Pending from '../pending';
import Failed from '../failed';

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [checkInLoading, setCheckInLoading] = useState(false);
  const [data, setData] = useState({});
  const [page, setPage] = useState('checkIn');
  const [locationCount, setLocationCount] = useState(0);

  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const checkInId = params.get('token');

  const { showAlertModal, setShowAlertModal, errorMessage, setErrorMessage } =
    useAlertHook();

  console.log('error message after assignment', errorMessage);

  useEffect(() => {
    console.log('error message is here', errorMessage);
    if (errorMessage && errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  const getVisistorId = async () => {
    const visitorId = 'FP_123454321';
    storeHelper(pc.visitorId, visitorId);
    // const fpPromise = FingerprintJS.load({
    //   token: 'rmqTlyQfydkhEoCPbigF',
    //   // endpoint: config.FP_DOMAIN,
    // });

    // fpPromise
    //   .then(fp => fp.get({ linkedId: checkInId }))
    //   .then(result => {
    //     const visitorId = result.visitorId;

    //     storeHelper(pc.visitorId, visitorId);
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });
  };

  useEffect(() => {
    localStorage.clear();
    storeHelper(pc.orderId, checkInId);
    // paralled call to get visitor id
    getVisistorId();

    setIsLoading(true);

    checkInInstance
      .get(`/scan-auth/authorization/need/${checkInId}`)
      .then(response => {
        setIsLoading(false);
        let data = response.data;
        if (data.status === 'success') {
          setData(data.response);
          if (data.response.status === 'success') {
            setPage('success');
          } else if (data.response.status === 'cancel') {
            setPage('cancel');
          } else {
            setPage('checkIn');
          }
        } else {
          setErrorMessage(data.message);
        }
      })
      .catch(err => {
        setIsLoading(false);
        setErrorMessage('Something went wrong');
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkInId]);

  const checkInCall = location => {
    if (data.authorization_type === 'prepaid') {
      prepaidCheckIn();
    } else {
      postpaidCheckIn();
    }
  };

  const prepaidCheckIn = () => {
    let location = retrieveHelper(pc.location);
    let visitorId = retrieveHelper(pc.visitorId);

    let payload = {
      order_id: checkInId,
      qr_code: 'prepaid',
      coordinates: location,
      fingerprint_id: visitorId,
    };
    setCheckInLoading(true);

    checkInInstance
      .post('/scan-auth/authorization', payload)
      .then(response => {
        setCheckInLoading(false);
        let data = response.data;
        if (data.status === 'success') {
          setPage('success');
        } else {
          setErrorMessage(data.message);
        }
      })
      .catch(err => {
        setCheckInLoading(false);
        setErrorMessage('Something went wrong');
      });
  };

  const postpaidCheckIn = () => {
    setCheckInLoading(false);
    setPage('scanner');
  };

  const showPosition = position => {
    let location = `${position.coords.latitude},${position.coords.longitude}`;
    storeHelper(pc.location, location);
    checkInCall(location);
  };

  const onLocationErr = err => {
    if (locationCount === 1) {
      let location = '0.000,0.000';
      storeHelper(pc.location, location);
      checkInCall(location);
    } else {
      setCheckInLoading(false);
      setErrorMessage('Please allow location permissions to Check-in');
      setLocationCount(1);
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      setCheckInLoading(true);
      navigator.geolocation.getCurrentPosition(showPosition, onLocationErr);
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };

  const handleCheckIn = () => {
    if (window.confirm('Are you sure?')) {
      getLocation();
    }
  };

  const handleCancel = () => {
    setCancelLoading(true);
    setTimeout(() => {
      setCancelLoading(false);
      setPage('cancel');
    }, 3000);
  };

  const getView = page => {
    if (page === 'checkIn') {
      return (
        <CheckIn
          handleCancel={handleCancel}
          handleCheckIn={handleCheckIn}
          cancelLoading={cancelLoading}
          checkInLoading={checkInLoading}
          data={data}
        />
      );
    } else if (page === 'scanner') {
      return <Scanner setPage={setPage} data={data} />;
    } else if (page === 'success') {
      return <Success data={data} checkInId={checkInId} />;
    } else if (page === 'pending') {
      return <Pending data={data} setPage={setPage} />;
    } else if (page === 'cancel') {
      return <Cancel data={data} />;
    } else if (page === 'failed') {
      return <Failed />;
    }
  };

  return (
    <Box
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      height="100%"
      // pt={5}
      // pb={10}
    >
      <Box flex={1}>{isLoading ? <Loader /> : getView(page)}</Box>

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          onClose={() => {
            setErrorMessage('');
            setShowAlertModal(false);
          }}
          message={errorMessage}
        />
      )}
    </Box>
  );
};

export default Home;
