import React, { useContext } from 'react';
import { Box, Text, Select } from '@chakra-ui/react';
import { px13 } from '../../constants';

import {
  retrieveHelper,
  pc,
  registerEvent,
  ec,
  actions,
  // capitalizeFirstLetter,
  // nameMapping,
} from '../../../utils';
import { ColorContext } from '../../cashlessLayout';
import { StarIcon } from '../../icons';

const Filters = ({
  topRated,
  setTopRated,
  subTypes,
  selectedSubType,
  setSelectedSubType,
  getProviders,
}) => {
  const { primary } = useContext(ColorContext);
  return (
    <Box display="flex" my={3} alignItems="center" width="100%">
      <Box
        height="32px"
        as="button"
        minW={['110px']}
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={2}
        bgColor={topRated ? primary : 'rgba(0, 88, 255, 0.1)'}
        borderRadius="7px"
        onClick={() => {
          registerEvent(ec.Listing, actions.TOP_RATED_CLICK);
          setTopRated(!topRated);
        }}
      >
        <StarIcon color={topRated ? 'white' : primary} mr={2} />
        <Text
          fontSize={px13}
          fontWeight="bold"
          color={topRated ? 'white' : primary}
        >
          Top Rated
        </Text>
      </Box>

      {/* <Box
        ml={2}
        height="32px"
        as="button"
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={2}
        bgColor={'rgba(0, 88, 255, 0.1)'}
        borderRadius="7px"
        disabled
        pointerEvents="none"
      >
        <Text fontSize={px12} fontWeight="bold" color={primaryColor}>
          {capitalizeFirstLetter(nameMapping[searchQuery.type])}
        </Text>
      </Box> */}

      {subTypes.length > 1 && (
        <Select
          pl={2}
          height="32px"
          width="175px"
          // placeholder="Service Type"
          bgColor="rgba(0, 88, 255, 0.1)"
          fontSize={px13}
          fontWeight="bold"
          color={primary}
          value={selectedSubType}
          onChange={e => {
            const value = e.target.value;
            setSelectedSubType(value);

            if (value.length > 0) {
              const searchQuery = retrieveHelper(pc.searchQuery);
              getProviders(
                searchQuery.type,
                e.target.value,
                topRated ? 'prominance' : 'distance'
              );
            }
          }}
        >
          {subTypes.map((value, index) => (
            <option key={index} value={value.toLowerCase()}>
              {value}
            </option>
          ))}
        </Select>
      )}
    </Box>
  );
};

export default Filters;
