import React, { useContext, useEffect, useState } from 'react';
import { Box, Stack, Text, Button } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';

import TransactionStatus from '../../scanUI/transactionsUI/transactionStatus';
import {
  retrieveHelper,
  pc,
  storeHelper,
  registerEvent,
  ec,
  actions,
} from '../../../utils';
import { primaryText, px16, px13 } from './../../constants';
import { ColorContext } from '../../cashlessLayout';

const PgFailure = () => {
  const qrName = retrieveHelper(pc.qrName);

  const [paidAmount, setPaidAmount] = useState('');

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let data = queryParams.get('data');
    data = JSON.parse(window.atob(data));
    storeHelper('cashless_patientPayable', data.amount);
    setPaidAmount(retrieveHelper('cashless_patientPayable'));
    registerEvent(ec.InAppPartialTransaction, actions.PARTIAL_PAYMENT_FAILED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { primary } = useContext(ColorContext);
  return (
    <Box
      margin="auto"
      className="containerHeight"
      style={{ backgroundColor: '#fff' }}
      pt={5}
    >
      <Box
        display="flex"
        flexDir="column"
        flex={1}
        mt={10}
        height="100%"
        width="90%"
        margin="auto"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box width="100%" p={5} borderRadius="5px" bgColor="white">
          <TransactionStatus state="fail" />

          <Stack color={primaryText} textAlign="center">
            <Stack spacing={0} mt={5} fontSize={px16} fontWeight="bold">
              <Text className="poppins fw-500">Payment Failed </Text>
              <Text className="poppins fw-500">of &#8377;{paidAmount}</Text>
            </Stack>
            <Stack spacing={0} pt={5} fontSize={px16}>
              <Text className="poppins fw-400">
                Payment to <strong>{qrName}</strong> failed.{' '}
              </Text>
            </Stack>
          </Stack>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          flexDir="column"
          width="100%"
          mb="2vh"
        >
          <Button
            isFullWidth
            minH={['40px']}
            _active={{
              bgColor: { primary },
              color: 'white',
            }}
            _hover={{
              bgColor: { primary },
              color: 'white',
            }}
            bgColor={primary}
            color="white"
            variant="solid"
            size="md"
            onClick={() => {
              history.push('/cashless/transaction');
            }}
          >
            <Text className="poppins fw-600" fontSize={px13}>
              Try Again
            </Text>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PgFailure;
