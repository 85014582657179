import React, { useContext } from 'react';
import { Box, VStack, Button, Stack, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router';
import { format, parse } from 'date-fns';
import { px14, px16, px12, px13 } from '../../constants';
import TransactionStatus from '../../scanUI/transactionsUI/transactionStatus';
import { retrieveHelper, pc, registerEvent, ec, actions } from '../../../utils';
import PoweredBy from '../../subComponents/poweredBy';
import { ColorContext } from '../../cashlessLayout';
import { UploadIcon } from '../../icons';

const SuccessTransaction = ({ state, transaction }) => {
  const history = useHistory();
  const QRName = retrieveHelper(pc.qrName);
  const paidAmount = retrieveHelper('cashless_paidAmount')
    ? retrieveHelper('cashless_paidAmount')
    : transaction.payment_amount;

  const getModifiedAt = transaction => {
    try {
      return format(
        parse(
          transaction.payment_completed_at.split('.')[0],
          'dd/MM/yyyy HH:mm:ss',
          new Date()
        ),
        "hh:mm aaaaa'm' | dd-MM-yyyy "
      );
    } catch (err) {
      return '';
    }
  };

  const { primary } = useContext(ColorContext);
  return (
    <Box
      display="flex"
      flexDir="column"
      flex={1}
      mt={10}
      height="100%"
      width="100%"
      margin="auto"
      justifyContent="space-between"
      alignItems="center"
      style={{ padding: '5% 5% 0 5%' }}
    >
      <img
        src={retrieveHelper('featureList')?.logo_url}
        alt="logo_black"
        style={{ margin: '0 30%', width: '40%' }}
      />
      <Box
        width="100%"
        borderRadius="5px"
        bgColor="white"
        //boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
      >
        <TransactionStatus state={state} transaction={transaction} />

        <Stack textAlign="center" spacing={0} mt={2}>
          <Text fontSize="32px" className="poppins fw-700" color={primary}>
            ₹ {paidAmount}
          </Text>
          <Text fontSize={px16} className="poppins fw-500" color="#536474">
            Payment Successful
          </Text>

          <Box display="flex" justifyContent="center" py={5} fontSize={px14}>
            <VStack>
              <Box display="flex" width="100%" fontSize={px12}>
                <VStack mt={10}>
                  <Text
                    textAlign="center"
                    color="#536474"
                    fontSize="18px"
                    className="poppins fw-500"
                  >
                    {QRName}
                  </Text>
                  <Text
                    textAlign="center"
                    color="#536474"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    fontSize="14px"
                    className="poppins fw-500"
                  >
                    {getModifiedAt(transaction)}
                  </Text>
                  <Text
                    textAlign="center"
                    color="#8EA4BB"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    fontSize="12px"
                    className="poppins fw-500"
                  >
                    {transaction.transaction_id}
                  </Text>
                </VStack>
              </Box>
            </VStack>
          </Box>
        </Stack>
      </Box>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        flexDir="column"
        width="100%"
      >
        <Text
          color="#8EA4BB"
          className="poppins fw-500"
          fontSize="12px"
          textAlign="center"
          my={2}
          mx={15}
        >
          Uploading your prescription and bills will let you keep using services
        </Text>
        <Button
          isFullWidth
          minH={['40px']}
          color={primary}
          colorScheme={primary}
          variant="outline"
          size="md"
          leftIcon={<UploadIcon color={primary} />}
          onClick={() => {
            registerEvent(ec.InAppTransaction, actions.UPLOAD_BILL_CLICK);
            history.push('/cashless/upload');
          }}
          mb="1vh"
        >
          <Text className="poppins fw-600" fontSize={px13}>
            Upload Prescription and Bills
          </Text>
        </Button>
        <PoweredBy logoType="black" />

        {/* <Box
          as="button"
          height={['40px']}
          fontSize={px13}
          onClick={() => {
            const employeeId = retrieveHelper(pc.employeeId);
            history.push(`/?id=${employeeId}`);
          }}
        >
          <Text color={primaryColor}>Back Home</Text>
        </Box> */}
      </Box>
    </Box>
  );
};

export default SuccessTransaction;
