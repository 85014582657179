import {
  DoctorIcon,
  HospitalIcon,
  LabIcon,
  OthersIcon,
  PharmacyIcon,
} from '../icons';

export const getTypeIcon = (type, imageSize, color) => {
  if (type.includes('hospital')) {
    return <HospitalIcon color={color} width={imageSize} height={imageSize} />;
  } else if (type.includes('pharmacy')) {
    return <PharmacyIcon color={color} width={imageSize} height={imageSize} />;
  } else if (type.includes('diagnostic')) {
    return <LabIcon color={color} width={imageSize} height={imageSize} />;
  } else if (type.includes('doctors')) {
    return <DoctorIcon color={color} width={imageSize} height={imageSize} />;
  } else {
    return <OthersIcon color={color} width={imageSize} height={imageSize} />;
  }
};
