import React, { useContext } from 'react';
import { Box, Text, HStack, Image, VStack } from '@chakra-ui/react';
import { px16 } from '../../constants';
import hiImage from './../../../../assets/images/hi.png';
import HomeCard from './homeCard';
import { storeHelper, pc, registerEvent, ec } from './../../../utils';
import { ColorContext } from '../../cashlessLayout';
import {
  DentistIcon,
  DoctorIcon,
  HospitalIcon,
  LabIcon,
  OthersIcon,
  PharmacyIcon,
} from '../../icons';

const HomePage = ({
  error,
  history,
  setErrorMessage,
  contactNumber,
  sessionResponse,
}) => {
  const { primary } = useContext(ColorContext);
  const cards = [
    {
      icon: <DoctorIcon color={primary} boxSize={['33px']} />,
      text: 'Doctor',
      onClick: () => {
        let searchQuery = {
          type: 'doctors',
          keyword: 'general physician',
        };
        registerEvent(ec.Home, 'CARD_CLICKED_DOCTORS');
        storeHelper(pc.searchQuery, searchQuery);
        history.push('/cashless/listing');
      },
    },
    {
      icon: <PharmacyIcon color={primary} boxSize={['33px']} />,
      text: 'Medical Store',
      onClick: () => {
        let searchQuery = {
          type: 'pharmacy',
          keyword: 'near me',
        };
        registerEvent(ec.Home, 'CARD_CLICKED_PHARMACY');
        storeHelper(pc.searchQuery, searchQuery);
        history.push('/cashless/listing');
      },
    },
    {
      icon: <HospitalIcon color={primary} boxSize={['33px']} />,
      text: 'Hospital',
      onClick: () => {
        let searchQuery = {
          type: 'hospitals',
          keyword: 'near me',
        };
        registerEvent(ec.Home, 'CARD_CLICKED_HOSPITALS');
        storeHelper(pc.searchQuery, searchQuery);
        history.push('/cashless/listing');
      },
    },
    {
      icon: <LabIcon color={primary} boxSize={['33px']} />,
      text: 'Lab',
      onClick: () => {
        let searchQuery = {
          type: 'diagnostics',
          keyword: 'near me',
        };
        registerEvent(ec.Home, 'CARD_CLICKED_LABS');
        storeHelper(pc.searchQuery, searchQuery);
        history.push('/cashless/listing');
      },
    },
    {
      icon: <DentistIcon color={primary} boxSize={['33px']} />,
      text: 'Dentist',
      onClick: () => {
        let searchQuery = {
          type: 'doctors',
          keyword: 'dentist',
        };
        registerEvent(ec.Home, 'CARD_CLICKED_DOCTORS_DENTIST');
        storeHelper(pc.searchQuery, searchQuery);
        history.push('/cashless/listing');
      },
    },
    {
      icon: <OthersIcon color={primary} boxSize={['33px']} />,
      text: 'More Services',
      onClick: () => {
        let searchQuery = {
          type: 'others',
          keyword: 'optician',
        };
        registerEvent(ec.Home, 'CARD_CLICKED_OTHERS');
        storeHelper(pc.searchQuery, searchQuery);
        history.push('/cashless/listing');
      },
    },
  ];
  return (
    <Box
      className="containerHeight"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Box width="100%" overflow="scroll" mb="70px" pt="55px">
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          maxW={['500px']}
          width="100%"
          margin="auto"
          height={'40px'}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bgColor={primary}
        >
          <HStack
            spacing={1}
            pl="5%"
            width={'200px'}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            <Text fontSize={px16} color="white">
              Hi, {sessionResponse && sessionResponse?.customer_details?.name}
            </Text>
            <Image
              boxSize="18px"
              objectFit="cover"
              src={hiImage}
              alt="hand wave"
            />
          </HStack>
          {/* <HStack spacing={1} pr="5%">
            <Text fontSize={px16} fontWeight="bold" color="white">
              ₹
              {sessionResponse != null
                ? Number.parseFloat(
                    sessionResponse?.sum_insured
                  ).toLocaleString()
                : '0'}
            </Text>
          </HStack> */}
        </Box>

        <Box width="90%" margin="auto">
          <Box>
            <VStack>
              <HStack
                display="flex"
                justifyContent="space-between"
                width="100%"
              >
                <HomeCard
                  disable={primary === '#333' ? true : false}
                  key={cards[0].text}
                  icon={cards[0].icon}
                  text={cards[0].text}
                  onClick={cards[0].onClick}
                  width="48%"
                />
                <HomeCard
                  disable={primary === '#333' ? true : false}
                  key={cards[1].text}
                  icon={cards[1].icon}
                  text={cards[1].text}
                  onClick={cards[1].onClick}
                  width="48%"
                />
              </HStack>

              <HStack
                display="flex"
                justifyContent="space-between"
                width="100%"
              >
                <HomeCard
                  disable={primary === '#333' ? true : false}
                  key={cards[2].text}
                  icon={cards[2].icon}
                  text={cards[2].text}
                  onClick={cards[2].onClick}
                  width="48%"
                />
                <HomeCard
                  disable={primary === '#333' ? true : false}
                  key={cards[3].text}
                  icon={cards[3].icon}
                  text={cards[3].text}
                  onClick={cards[3].onClick}
                  width="48%"
                />
              </HStack>

              <HStack
                display="flex"
                justifyContent="space-between"
                width="100%"
              >
                <HomeCard
                  disable={primary === '#333' ? true : false}
                  key={cards[4].text}
                  icon={cards[4].icon}
                  text={cards[4].text}
                  onClick={cards[4].onClick}
                  width="48%"
                />
                <HomeCard
                  disable={primary === '#333' ? true : false}
                  key={cards[5].text}
                  icon={cards[5].icon}
                  text={cards[5].text}
                  onClick={cards[5].onClick}
                  width="48%"
                />
              </HStack>
            </VStack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;
