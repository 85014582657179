import React from 'react';
import {
  Box,
  Image,
  Text,
  //  Button
} from '@chakra-ui/react';

import {
  // primaryColor,
  primaryText,
  px13,
  px16,
} from '../../constants';
// import { useHistory } from 'react-router-dom';
// import { retrieveHelper, pc } from './../../../utils';
import SuccessLogoNew from '../../icons/check.png';
import PoweredBy from '../../subComponents/poweredBy';
import { retrieveHelper } from '../../../utils';

const SuccessUpload = () => {
  // const history = useHistory();

  return (
    <Box
      width="100%"
      margin="auto"
      p={5}
      bgColor="white"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      className="containerHeight"
      style={{ backgroundColor: '#fff', padding: '5% 5% 0 5%' }}
    >
      <Box>
        <Image
          margin="0 30%"
          width="40%"
          src={retrieveHelper('featureList')?.logo_url}
          alt="logo_black"
        />
      </Box>
      <Box
        borderRadius={['5px']}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          mr="3.5%"
          width={['85px']}
          height={['85px']}
          objectFit="cover"
          src={SuccessLogoNew}
          alt="success"
        />
        <Text fontSize={px16} color={primaryText} className="poppins fw-600">
          Documents Uploaded Successfully
        </Text>
        <Text
          className="poppins fw-500"
          color={primaryText}
          fontSize={px13}
          pt={10}
        >
          Thank You!
        </Text>
      </Box>

      <PoweredBy logoType="black" />
    </Box>
  );
};

export default SuccessUpload;
