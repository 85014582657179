import React, { useState, useEffect, useContext } from 'react';
import { Box, Text, Input, Button } from '@chakra-ui/react';
import { cashlessInstance } from '../../../api';
import {
  storeHelper,
  pc,
  parseErrorMessage,
  registerEvent,
  ec,
  actions,
} from '../../../utils';
import useAlertHook from '../../hooks/errorHook';
import { useHistory } from 'react-router-dom';
import AlertModal from '../../subComponents/alertModal/alertModal';
import { primaryText, px13, px16, secondryText } from './../../constants';
import { ColorContext } from '../../cashlessLayout';

const ManualPay = () => {
  const [upiID, setUpiID] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { primary } = useContext(ColorContext);

  const { errorMessage, setErrorMessage, showAlertModal, setShowAlertModal } =
    useAlertHook();

  const history = useHistory();

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useEffect(() => {
    localStorage.removeItem('qrName');
    localStorage.removeItem('qrCode');
  }, []);

  const handleValidateCode = () => {
    const payload = {
      payment_qr_code: upiID,
      is_scanned: false,
    };

    registerEvent(ec.Pay, actions.MANUAL_PAYMENT_METHOD_VALIDATE_INIT);

    setIsLoading(true);

    cashlessInstance
      .post('/opd/cashless/validate-upi', payload)
      .then(response => {
        setIsLoading(false);
        let data = response.data;
        if (data.status === 'success') {
          registerEvent(ec.Pay, actions.MANUAL_PAYMENT_METHOD_VALIDATE_SUCCESS);
          storeHelper(pc.paymentType, 'manual');
          if (data.response.provider_account_name == null) {
            storeHelper(pc.qrName, '');
          } else {
            storeHelper(pc.qrName, data.response.provider_account_name);
          }
          storeHelper(pc.qrCode, data.response.payment_upi_id);
          if (data.response.provider_account_name) {
            history.push('/cashless/transaction');
          }
        } else {
          setErrorMessage(data.message);
        }
      })
      .catch(err => {
        setIsLoading(false);
        const errMsg = parseErrorMessage(err);
        setErrorMessage(errMsg);
      });
  };

  return (
    <>
      <Box
        display="flex"
        flexDir="column"
        justifyContent="space-between"
        className="containerHeight"
      >
        <Box
          width="90%"
          margin="auto"
          mt={5}
          p={5}
          bgColor="white"
          boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
          borderRadius="5px"
        >
          <Text
            fontSize={px16}
            textAlign="center"
            fontWeight="bold"
            color={primaryText}
          >
            Pay using UPI ID
          </Text>
          <Box mt={5}>
            <Text fontSize={px13} mb={2} color={secondryText}>
              Enter UPI ID
            </Text>
            <Input
              width="100%"
              height={['36px']}
              type="text"
              autoFocus={true}
              fontSize={px16}
              value={upiID}
              onChange={event => {
                setUpiID(event.target.value);
              }}
              bgColor="white"
            />
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
          width="90%"
          margin="auto"
          mb="3vh"
        >
          <Button
            isFullWidth
            _active={{
              bgColor: { primary },
              color: 'white',
            }}
            _hover={{
              bgColor: { primary },
              color: 'white',
            }}
            bgColor={primary}
            height={['40px']}
            color="white"
            variant="solid"
            disabled={!upiID?.trim().includes('@')}
            onClick={() => {
              handleValidateCode();
            }}
            isLoading={isLoading}
          >
            <Text fontSize={px13} fontWeight="bold">
              Next
            </Text>
          </Button>

          <Box
            as="button"
            height={['40px']}
            fontSize={px13}
            onClick={() => {
              history.goBack();
            }}
          >
            <Text color={primary}>Go Back</Text>
          </Box>
        </Box>
      </Box>

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          onClose={() => {
            setShowAlertModal(false);
            setErrorMessage('');
          }}
          message={errorMessage}
        />
      )}
    </>
  );
};

export default ManualPay;
