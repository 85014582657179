import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { Switch, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { ErrorBoundary } from 'react-error-boundary';

import config from './config';
import './App.css';
// import Home from './components/oldScreens/pages/home';
// import ManualPay from './components/oldScreens/pages/manualPay';
// import Transaction from './components/oldScreens/pages/transaction';
// import Scanner from './components/oldScreens/pages/scanner';
// import UploadBill from './components/oldScreens/pages/uploadBill';
import Wrapper from './components/wrapper';

import Home from './components/scan/scanPages/home';
import Listings from './components/scan/scanPages/listings';
import Scanner from './components/scan/scanPages/scanner';
import ManualPay from './components/scan/scanPages/manualPay';
import Transaction from './components/scan/scanPages/transaction';
import UploadBill from './components/scan/scanPages/uploadBill';
import TransactionStatus from './components/scan/scanPages/transaction/transactionStatus';

import CheckInHome from './components/checkIn/checkInPages/home';
import Fallback from './components/error/fallback';
import FallbackCheckIn from './components/error/fallbackCheckIn';
import BillSuccess from './components/scan/scanPages/billPay/success';
import View from './components/onboarding/views';
import OTCView from './components/otc-onboarding/views';
import PaymentError from './components/scan/scanPages/paymentError';
import { instance } from './components/api';
import Error404 from './components/error/404';
import BillPayment from './components/scan/scanPages/billPay';
import LoginPage from './components/otc-onboarding/loginForm';
import UserView from './components/userOnboarding/views';
import SelfView from './components/selfOnboarding/views';

// in App Views
import InAppListing from './components/scan-inApp/scanPages/listings';
import InAppScanner from './components/scan-inApp/scanPages/scanner';
import InAppTransaction from './components/scan-inApp/scanPages/transaction';
import InAppUploadBill from './components/scan-inApp/scanPages/uploadBill';
import InAppTransactionStatus from './components/scan-inApp/scanPages/transaction/transactionStatus';
import InAppPaymentDetail from './components/scan-inApp/scanPages/transaction/paymentDetail';
import PgSuccess from './components/scan-inApp/scanPages/transaction/pgSuccess';
import PgFailure from './components/scan-inApp/scanPages/transaction/pgFailure';

// cashless Views
import CashlessLayout from './components/scan-cashless/cashlessLayout';
import CashlessHome from './components/scan-cashless/scanPages/home';
import CashlessListing from './components/scan-cashless/scanPages/listings';
import CashlessScanner from './components/scan-cashless/scanPages/scanner';
import CashlessTransaction from './components/scan-cashless/scanPages/transaction';
import CashlessManualPay from './components/scan-cashless/scanPages/manualPay';
import CashlessPaymentDetail from './components/scan-cashless/scanPages/transaction/paymentDetail';
import CashlessTransactionStatus from './components/scan-cashless/scanPages/transaction/transactionStatus';
import CashlessUploadBill from './components/scan-cashless/scanPages/uploadBill';
import CashlessPgSuccess from './components/scan-cashless/scanPages/transaction/pgSuccess';
import CashlessPgFailure from './components/scan-cashless/scanPages/transaction/pgFailure';

import { retrieveHelper } from './components/utils';

// import { ColorModeSwitcher } from './ColorModeSwitcher';

function App() {
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const checkInId = params.get('token');
  const userId = params.get('id')
    ? params.get('id')
    : retrieveHelper('employeeId');
  let inAppUser = null;

  try {
    inAppUser = JSON.parse(window.atob(params.get('data')))['user_id'];
  } catch (error) {}

  const checkInPage = checkInId != null;

  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      // We execute the same script as before

      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }, []);

  useEffect(() => {
    const currUrl = window.location.href;
    let uaCode;
    if (
      currUrl.includes('inApp') ||
      currUrl.includes('partner') ||
      currUrl.includes('payment/cashless')
    ) {
      uaCode = config.UA_CODE_INAPP;
    } else if (checkInId) {
      uaCode = config.UA_CODE_CHECKIN;
    } else {
      uaCode = config.UA_CODE;
    }
    //console.log("uacode", uaCode);
    const uaConfig = {
      debug: false, // config.ENV === 'DEV'
      gaOptions: {
        userId: userId || checkInId || inAppUser,
      },
    };
    // console.log('initializing...');
    ReactGA.initialize(uaCode, { ...uaConfig });
    if (checkInId) {
      ReactGA.pageview('checkin');
    } else {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [userId, checkInId, inAppUser]);

  const errorHandler = (error, errorInfo) => {
    const payload = {
      error_message: `CCP ||| ${error} ||| ${JSON.stringify(errorInfo)}`,
    };
    if (config.ENV === 'PROD') {
      instance.post('/opd/report-error', payload);
    }
    // console.log('logging', error, errorInfo);
  };

  const checkInErrorHandler = (error, errorInfo) => {
    const payload = {
      error_message: `CheckIN ||| ${error} ||| ${JSON.stringify(errorInfo)}`,
    };
    if (config.ENV === 'PROD') {
      instance.post('/opd/report-error', payload);
    }
    // console.log('logging', error, errorInfo);
  };

  return (
    // <ChakraProvider theme={customTheme}>
    <Box className="containerHeight">
      {checkInPage ? (
        <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
          <ErrorBoundary
            FallbackComponent={FallbackCheckIn}
            onError={checkInErrorHandler}
          >
            <Box className="containerHeight" bgColor="#F2F6FE">
              <Box width="100%" margin="auto" height="100%">
                <CheckInHome />
              </Box>
            </Box>
          </ErrorBoundary>
        </Box>
      ) : (
        <Wrapper>
          <Switch>
            <Route exact path="/onboarding">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <View />
              </ErrorBoundary>
            </Route>
            <Route exact path="/login">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <LoginPage />
              </ErrorBoundary>
            </Route>
            <Route exact path="/otc-onboarding">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <OTCView />
              </ErrorBoundary>
            </Route>

            <Route exact path="/self-onboarding">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <SelfView />
              </ErrorBoundary>
            </Route>

            <Route exact path="/user-onboarding">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <UserView />
              </ErrorBoundary>
            </Route>

            <Route exact path="/">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                  <Home />
                </Box>
              </ErrorBoundary>
            </Route>
            <Route exact path="/listing">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                  <Listings />
                </Box>
              </ErrorBoundary>
            </Route>
            <Route exact path="/scanner">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                  <Scanner />
                </Box>
              </ErrorBoundary>
            </Route>
            <Route exact path="/pay">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                  <ManualPay />
                </Box>
              </ErrorBoundary>
            </Route>
            <Route exact path="/transaction">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                  <Transaction />
                </Box>
              </ErrorBoundary>
            </Route>
            <Route exact path="/transactionStatus/:transactionId">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                  <TransactionStatus />
                </Box>
              </ErrorBoundary>
            </Route>
            <Route exact path="/upload">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                  <UploadBill />
                </Box>
              </ErrorBoundary>
            </Route>

            <Route exact path="/bill-status">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <BillSuccess />
              </ErrorBoundary>
            </Route>

            <Route exact path="/error">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <PaymentError />
              </ErrorBoundary>
            </Route>

            <Route exact path="/payment-gateway">
              <BillPayment />
            </Route>

            {/* inApp routes */}

            <Route exact path="/partner/opd">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                  <InAppScanner />
                </Box>
              </ErrorBoundary>
            </Route>

            <Route exact path="/partner/opd/upload">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                  <InAppUploadBill />
                </Box>
              </ErrorBoundary>
            </Route>

            <Route exact path="/inAppListing">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                  <InAppListing />
                </Box>
              </ErrorBoundary>
            </Route>
            <Route exact path="/inAppScanner">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                  <InAppScanner />
                </Box>
              </ErrorBoundary>
            </Route>
            {/* <Route exact path="/inAppPay">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                  <ManualPay />
                </Box>
              </ErrorBoundary>
            </Route> */}
            <Route exact path="/inAppTransaction">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                  <InAppTransaction />
                </Box>
              </ErrorBoundary>
            </Route>
            <Route exact path="/inAppTransactionStatus/:transactionId">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                  <InAppTransactionStatus />
                </Box>
              </ErrorBoundary>
            </Route>
            <Route exact path="/inAppUpload">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                  <InAppUploadBill />
                </Box>
              </ErrorBoundary>
            </Route>
            <Route exact path="/inAppPaymentDetail">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                  <InAppPaymentDetail />
                </Box>
              </ErrorBoundary>
            </Route>
            <Route exact path="/opd/payment/cashless/success">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                  <PgSuccess />
                </Box>
              </ErrorBoundary>
            </Route>
            <Route exact path="/opd/payment/cashless/failed">
              <ErrorBoundary
                FallbackComponent={Fallback}
                onError={errorHandler}
              >
                <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                  <PgFailure />
                </Box>
              </ErrorBoundary>
            </Route>

            {/* inApp routes end */}

            {/* cashless routes */}
            <CashlessLayout>
              <Route exact path="/cashless/opd">
                <ErrorBoundary
                  FallbackComponent={Fallback}
                  onError={errorHandler}
                >
                  <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                    <CashlessHome />
                  </Box>
                </ErrorBoundary>
              </Route>
              <Route exact path="/cashless/scanner">
                <ErrorBoundary
                  FallbackComponent={Fallback}
                  onError={errorHandler}
                >
                  <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                    <CashlessScanner />
                  </Box>
                </ErrorBoundary>
              </Route>
              <Route exact path="/cashless/transaction">
                <ErrorBoundary
                  FallbackComponent={Fallback}
                  onError={errorHandler}
                >
                  <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                    <CashlessTransaction />
                  </Box>
                </ErrorBoundary>
              </Route>
              <Route exact path="/cashless/transactionStatus/:transactionId">
                <ErrorBoundary
                  FallbackComponent={Fallback}
                  onError={errorHandler}
                >
                  <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                    <CashlessTransactionStatus />
                  </Box>
                </ErrorBoundary>
              </Route>
              <Route exact path="/cashless/upload">
                <ErrorBoundary
                  FallbackComponent={Fallback}
                  onError={errorHandler}
                >
                  <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                    <CashlessUploadBill />
                  </Box>
                </ErrorBoundary>
              </Route>
              <Route exact path="/cashless/paymentDetail">
                <ErrorBoundary
                  FallbackComponent={Fallback}
                  onError={errorHandler}
                >
                  <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                    <CashlessPaymentDetail />
                  </Box>
                </ErrorBoundary>
              </Route>
              <Route exact path="/cashless/pay">
                <ErrorBoundary
                  FallbackComponent={Fallback}
                  onError={errorHandler}
                >
                  <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                    <CashlessManualPay />
                  </Box>
                </ErrorBoundary>
              </Route>
              <Route exact path="/cashless/opd/success">
                <ErrorBoundary
                  FallbackComponent={Fallback}
                  onError={errorHandler}
                >
                  <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                    <CashlessPgSuccess />
                  </Box>
                </ErrorBoundary>
              </Route>
              <Route exact path="/cashless/opd/failed">
                <ErrorBoundary
                  FallbackComponent={Fallback}
                  onError={errorHandler}
                >
                  <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                    <CashlessPgFailure />
                  </Box>
                </ErrorBoundary>
              </Route>
              <Route exact path="/cashless/listing">
                <ErrorBoundary
                  FallbackComponent={Fallback}
                  onError={errorHandler}
                >
                  <Box maxW="500px" margin="auto" bgColor="#EFEFEF">
                    <CashlessListing />
                  </Box>
                </ErrorBoundary>
              </Route>
            </CashlessLayout>
            {/* cashless routes end */}

            <Route path="">
              <Error404 />
            </Route>

            <Route path="*">
              <Error404 />
            </Route>
          </Switch>
        </Wrapper>
      )}
    </Box>
    // </ChakraProvider>
  );
}

export default App;
