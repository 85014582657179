import React, { useContext, useEffect, useState } from 'react'; // { useState, useEffect }
import { Box } from '@chakra-ui/react';
import { useLocation, useHistory } from 'react-router';
import useAlertHook from '../../hooks/errorHook';
import AlertModal from '../../subComponents/alertModal/alertModal';
import HomePage from '../../scanUI/homeUI';
import OverlayLoader from '../../subComponents/overlayLoader';
import {
  actions,
  ec,
  parseErrorMessage,
  pc,
  registerEvent,
  storeHelper,
} from '../../../utils';
import { cashlessInstance } from '../../../api';
import { ColorContext } from '../../cashlessLayout';

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [progressMessage, setProgressMessage] = useState('');
  const [sessionResponse, setSessionResponse] = useState(null);
  const [error, setError] = useState(false);

  const { showAlertModal, setShowAlertModal, errorMessage, setErrorMessage } =
    useAlertHook();

  const location = useLocation();
  const history = useHistory();

  const { setPrimary } = useContext(ColorContext);
  const getFeaturesList = partner => {
    cashlessInstance.get(`/opd/cashless/features/${partner}`).then(response => {
      if (response.data.status === 'success') {
        storeHelper('featureList', response.data.response);
        setPrimary(response.data.response?.theme?.primary_color);
      }
    });
  };

  const getSessionDetails = (sessionId, page) => {
    setIsLoading(true);
    setProgressMessage('Fetching details');
    registerEvent(ec.Home, actions.AUTH_INIT);
    cashlessInstance
      .get(`/opd/cashless/session/${sessionId}`)
      .then(response => {
        let data = response.data;
        setIsLoading(false);
        if (data.status === 'success') {
          getFeaturesList(data.response?.partner_name);
          //getFeaturesList('lombard');
          setSessionResponse(data.response);
          storeHelper('sessionData', data.response);
          storeHelper(pc.location, data.response?.coordinates);
          if (page && page !== 'default') {
            storeHelper(pc.searchQuery, {
              type: page,
              keyword: page === 'doctors' ? 'general physician' : 'near me',
              location: data.response?.coordinates,
              time: new Date(),
            });
            history.push(`/cashless/listing`);
          }
        } else {
          setIsLoading(false);
          setErrorMessage(data.message);
        }
      })
      .catch(err => {
        setIsLoading(false);
        const errMsg = parseErrorMessage(err);
        setErrorMessage(errMsg);
      });
  };

  useEffect(() => {
    localStorage.clear();
    const params = new URLSearchParams(location.search);
    let sess_id = params.get('session_id');
    let page = params.get('page');
    storeHelper('page_session', { page, sess_id });

    if (!sess_id || !page) {
      setErrorMessage('Please use correct URL.');
    } else {
      // get the user Info from session ID
      getSessionDetails(sess_id, page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
      setError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  return (
    <Box
      className="containerHeight"
      display="flex"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      {isLoading ? (
        <OverlayLoader message={progressMessage} />
      ) : (
        <Box width="100%">
          <HomePage
            error={error}
            history={history}
            sessionResponse={sessionResponse}
            setErrorMessage={setErrorMessage}
          />
        </Box>
      )}

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          message={errorMessage}
          link=""
          onClose={() => {
            if (errorMessage.includes('allow location')) {
              document.getElementById('locationButton').click();
            }
            setErrorMessage('');
            setShowAlertModal(false);
          }}
        />
      )}
    </Box>
  );
};

export default Home;
